import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { LOGGED_IN, LOG_OUT, GET_2FA_DETAILS, CLEAR_2FA_DETAILS } from "src/actions/type";

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
  removeItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.removeItem(key);
    });
  },
};

export const loginTracking = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/login/tracking`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      return resData.data;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const login = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/login`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      if (resData.data?.two_factor_enabled){
        dispatch({ type: GET_2FA_DETAILS, payload: resData.data });
      } else {
        await fetchData("POST", `${apiUrl}/activity`, {
          user_id: resData.data?._id,
          type: "LOGIN",
          data: { login_authorization: "", login_method: "BASIC_AUTH", login_portal: "ADMIN_PORTAL" },
        }, null, { Authorization: resData?.access_token ? resData?.access_token : null});
        dispatch({ type: LOGGED_IN, payload: resData.data });
        localStorage.setItem("userId", resData.data._id);
        localStorage.setItem("access_token", resData.access_token);
        localStorage.setItem("refresh_token", resData.refresh_token);
      }
      return {message: resData.message};
    } else if (resData.status === 401) {
      const newRes = await fetchData("POST", `${apiUrl}/regenerate-access-token`, {
        token: localStorage.getItem("refresh_token"),
        ...body,
      });

      const newResData = await newRes.json();

      if (newResData.status === 400) {
        throw new Error(newResData.message);
      }

      localStorage.setItem("access_token", newResData.access_token);

      dispatch({ type: LOGGED_IN, payload: newResData.data });

      return "User verification successful";
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const otpVerify = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/otp/verify`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      await fetchData("POST", `${apiUrl}/activity`, {
        user_id: resData.data?._id,
        type: "LOGIN",
        data: { login_authorization: "", login_method: "BASIC_AUTH", login_portal: "ADMIN_PORTAL" },
      }, null, { Authorization: resData?.access_token ? resData?.access_token : null});
      dispatch({ type: LOGGED_IN, payload: resData.data });
      dispatch({ type: CLEAR_2FA_DETAILS, payload: null });
      localStorage.setItem("userId", resData.data._id);
      localStorage.setItem("access_token", resData.access_token);
      localStorage.setItem("refresh_token", resData.refresh_token);
      return {message: resData.message};
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const changePasswordFirstTime = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/change-password`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      await fetchData("POST", `${apiUrl}/activity`, {
        user_id: resData.data._id,
        type: "LOGIN",
        data: { login_authorization: "", login_method: "BASIC_AUTH", login_portal: "ADMIN_PORTAL" },
      });
      dispatch({ type: LOGGED_IN, payload: resData.data });
      localStorage.setItem("userId", resData.data._id);
      localStorage.setItem("access_token", resData.access_token);
      localStorage.setItem("refresh_token", resData.refresh_token);
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAuthorizedUser =
  (signal = null) =>
    async (dispatch, getState) => {
      try {
        let userId = localStorage.getItem("userId");
        let impersonatedUserId = localStorage.getItem("impersonatedUserId");

        const res = await fetchData(
          "POST",
          `${apiUrl}/users/${impersonatedUserId ? 'get-details' : 'self-details'}`,
          {
            id: impersonatedUserId || userId,
            access_token: localStorage.getItem("access_token"),
          },
          signal
        );

        const resData = await res.json();

        if (resData.status === 200) {
          if (resData?.data?.type === 'user' && !impersonatedUserId) {
            const authLogout = async (site) => {
              const res = await fetchData("GET", `${apiUrl}/sso/default-list`);
              const data = await res.json();
              let impersonatedUserId = asyncLocalStorage.getItem("impersonatedUserId");
              if (impersonatedUserId) {
                asyncLocalStorage.removeItem("impersonatedUserId");
                window.location.href = "/pages";
              }
              
              asyncLocalStorage.removeItem("userId");
              asyncLocalStorage.removeItem("impersonatedUserId");
              asyncLocalStorage.removeItem("access_token");
              asyncLocalStorage.removeItem("refresh_token");

              dispatch({ type: LOG_OUT, payload: null });


              if (data?._id && site.sso_admin_redirect) {
                window.location.href = "/sso-logout";
              } else {
                window.location.href = "/login";
              }
            }
            return authLogout()
          }

          dispatch({ type: LOGGED_IN, payload: resData.data });
          return resData.message;
        } else if (resData.status === 401) {
          const newRes = await fetchData(
            "POST",
            `${apiUrl}/regenerate-access-token`,
            {
              token: localStorage.getItem("refresh_token"),
              id: localStorage.getItem("userId"),
            },
            signal
          );

          const newResData = await newRes.json();

          if (newResData.status === 400) {
            throw new Error(newResData.message);
          }

          localStorage.getItem("access_token", newResData.access_token);

          dispatch({ type: LOGGED_IN, payload: newResData.data });
          return "User verification successful";
        } else {
          throw new Error(resData.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const ssoLoginActivity = () => {
  fetchData("POST", `${apiUrl}/sso-login/activity`, {});
}

export const getUser =
  (signal = null) =>
    async (dispatch) => {
      try {
        let userId = localStorage.getItem("userId");
        let impersonatedUserId = localStorage.getItem("impersonatedUserId");

        const res = await fetchData(
          "POST",
          `${apiUrl}/users/get-details`,
          {
            id: impersonatedUserId || userId,
            access_token: localStorage.getItem("access_token"),
          },
          signal
        );

        const resData = await res.json();

        if (resData.status === 200) {
          dispatch({ type: LOGGED_IN, payload: resData.data });
          return resData.message;
        } else if (resData.status === 401) {
          const newRes = await fetchData(
            "POST",
            `${apiUrl}/regenerate-access-token`,
            {
              token: localStorage.getItem("refresh_token"),
              id: localStorage.getItem("userId"),
            },
            signal
          );

          const newResData = await newRes.json();

          if (newResData.status === 400) {
            throw new Error(newResData.message);
          }

          localStorage.getItem("access_token", newResData.access_token);

          dispatch({ type: LOGGED_IN, payload: newResData.data });
          return "User verification successful";
        } else {
          throw new Error(resData.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const verifyUser = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/verify-user`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      const { message, data } = resData;

      return { message, data };
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const verifyToken =
  (body, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("POST", `${apiUrl}/verify-token`, body, signal);
        const resData = await res.json();

        if (resData.status === 200) {
          const { message, data } = resData;

          return { message, data };
        } else {
          throw new Error(resData.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const changePassword = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/change-password`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const logout = (site) => async (dispatch) => {
  const res = await fetchData("GET", `${apiUrl}/sso/default-list`);
  const data = await res.json();
  let impersonatedUserId = asyncLocalStorage.getItem("impersonatedUserId");
  if (impersonatedUserId) {
    asyncLocalStorage.removeItem("impersonatedUserId");
    window.location.href = "/pages";
  }
  
  asyncLocalStorage.removeItem("userId");
  asyncLocalStorage.removeItem("impersonatedUserId");
  asyncLocalStorage.removeItem("access_token");
  asyncLocalStorage.removeItem("refresh_token");

  dispatch({ type: LOG_OUT, payload: null });


  if (data?._id && site.sso_admin_redirect) {
    window.location.href = "/sso-logout";
  } else {
    window.location.href = "/login";
  }
};

export const refreshTokenVerify = async () => {
  const refreshTokenRes = await fetch(`${apiUrl}/refresh-token/verify`, {
    method: 'POST',
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("refresh_token"),
    }),
    body: JSON.stringify({}),
  });

  let refreshTokenData = {}

  if (refreshTokenRes.status === 200) {
    refreshTokenData = await refreshTokenRes.json()
    localStorage.setItem('access_token', refreshTokenData.access_token)
    localStorage.setItem('refresh_token', refreshTokenData.refresh_token)
  }

  return { refreshTokenRes, refreshTokenData }
}
