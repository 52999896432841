import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { addGroup, editGroupData } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import Modal from "src/components/Shared/Modal";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceGroupAuthorizeUserComponent from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupAuthorizeUserComponent";
import WorkspaceImportUserModal from "src/components/Workspaces/Workspace/WorkspaceImportUserModal";
import WorkspaceTag from "src/components/Workspaces/WorkspaceTag";
import useFetch from "src/hooks/useFetch";
import MultiSelectObjectUsers from "../Shared/Forms/Selects/MultiSelectObject/MultiSelectObjectUsers";

const permissionJson = {
  _id: null,
  page_id: null,
  filters: [],
};

const GroupModalAdd = ({
  isOpen = false,
  closeModal = () => {},
  submitText,
  cancelText,
  title = "",
  secondaryTitle = "",
  defaultWorkspaceId = "",
  defaultStyles = {},
  defaultOptions = {},
  children,
  onSubmit = () => {},
  removeSubmit = false,
  removeClose = false,
  setRefresh = () => {},
  importable = false,
  loadUsers = () => {},
  ...props
}) => {
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [tagList, setTagList] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState({ _id: -1, name: "None selected" });
  const [selectedUser, setSelectedUser] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [permissionState, setPermissionState] = useState([]);
  const [group, setGroup] = useState({});

  const [importModalOpen, setImportModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successModalDisabled, setSuccessModalDisabled] = useState(false);

  const params = useParams();

  const {
    response: { data: users },
    status: { done: usersLoaded },
  } = useFetch(defaultWorkspaceId ? (props.workspaceDetails?._id ? (props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" ? `/domo-users/list` : "/users/list") : "") : "/users/list", { query: { active_status: true, authToken: props.workspaceDetails?.auth_token } });

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post" });

  const {
    response: { data: pages },
    status: { done: pagesLoaded },
    // } = useFetch("/pages/list");
  } = useFetch(`/pages/list${params.id ? "?workspace_id=" + params.id : ""}`);

  const {
    response: { data: activeSSO },
  } = useFetch("/sso/is-active");

  const handleSelectedUsers = (usersArray) => {
    let updatedSelectedUsers = usersArray.reduce((users, user) => (user.selected ? [...users, user.key] : users), []);
    setSelectedUser(updatedSelectedUsers);
  };

  const handleClearValues = () => {
    setGroup({});
    setName("");
    setTag("");
    setTagList([]);
    if (!defaultWorkspaceId) {
      setSelectedWorkspace({ _id: -1, name: "None selected" });
      setPageList([]);
    }
    setSelectedUser([]);
    setPermissionState([]);
    props.setIsOpen(false);
    props.setAddGroupStatus(false);
    props.setEditGroupStatus(false);
    props.setEditId(null);
  };

  useEffect(() => {
    if (defaultWorkspaceId && workspacesLoaded) {
      let defaultWorkspace = workspaces.find((workspace) => workspace._id === defaultWorkspaceId);
      setSelectedWorkspace({ _id: defaultWorkspace._id, name: defaultWorkspace.name });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, workspacesLoaded, defaultWorkspaceId]);

  useEffect(() => {
    if (selectedWorkspace && Array.isArray(pages)) {
      setPageList(pages.filter((page) => page.workspace_id?._id === selectedWorkspace._id && page.page_type !== "LANDING_PAGE"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages?.length, pagesLoaded, selectedWorkspace]);

  useEffect(() => {
    if (props?.editId && props.groups.length) {
      const findGroup = props.groups.find((group) => group._id === props?.editId);
      setGroup(findGroup);
      setName(findGroup?.name);
      setTagList(findGroup?.tags || []);
      setSelectedWorkspace(findGroup?.workspace_id);
      setPageList(pages.filter((pg) => pg.workspace_id?._id === findGroup?.workspace_id?._id && pg.page_type !== "LANDING_PAGE"));
      setSelectedUser(findGroup?.user_id);
      setPermissionState(findGroup.permissions.length > 0 ? findGroup.permissions : [permissionJson]);
    } else {
      handleClearValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.editId, props?.groups]);

  const {
    response: { data: operators },
  } = useFetch("/operators/list");

  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const updateWorkspace = async (updatedWorkspace) => {
    setSelectedWorkspace(updatedWorkspace);
    setPageList(pages.filter((pg) => pg.workspace_id?._id === updatedWorkspace._id && pg.page_type !== "LANDING_PAGE"));
    setPermissionState([]);
  };

  const createGroup = async (importStatus = undefined) => {
    try {
      setSuccessModalDisabled(true);
      const data = await props.addGroup({
        name: name,
        workspace_id: selectedWorkspace._id,
        user_id: selectedUser,
        permissions: permissionState,
        tags: tagList,
        auth_token: props.workspaceDetails?.auth_token,
        importStatus,
        sendWelcomeEmail,
        password,
        confirmPassword,
      });
      handleClearValues();

      setRefresh(selectedUser);
      setSuccessModalDisabled(false);
      toast.success(data.message);
    } catch (error) {
      setSuccessModalDisabled(false);
      toast.error(error.message);
    }
  };

  const editGroup = async () => {
    try {
      const data = await props.editGroupData({
        id: group?._id,
        name: name,
        workspace_id: selectedWorkspace._id,
        user_id: selectedUser,
        permissions: permissionState,
        tags: tagList,
        auth_token: props.workspaceDetails?.auth_token,
      });
      handleClearValues();

      setRefresh(selectedUser);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const groupModalSubmit = async (importStatus = undefined) => {
    try {
      if (importable && typeof importStatus !== "boolean" && selectedUser.length && props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        if (users.filter((user) => selectedUser.includes(user.id || user._id) && !user.portalUser).length) {
          if (props.addGroupStatus) {
            // group validation
            try {
              const groupValRes = await apiRequest("post", "/groups-validation", {
                body: {
                  name: name,
                  workspace_id: selectedWorkspace._id,
                  user_id: selectedUser,
                  permissions: permissionState,
                  tags: tagList,
                  auth_token: props.workspaceDetails?.auth_token,
                },
              });

              if (groupValRes.data && groupValRes.data.status !== 200) {
                toast.error(groupValRes.data.message);
                return;
              }
            } catch (error) {
              // toast
              toast.error(error.message);
              return;
            }
          }

          props.setIsOpen(false);
          setImportModalOpen(true);
          return;
        }
      }

      if (props.addGroupStatus) {
        await createGroup(importStatus);
        loadUsers();
        setImportModalOpen(false);
        closeModal();
      } else if (props.editGroupStatus) {
        await editGroup();
        setImportModalOpen(false);
        closeModal();
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }

    return;
  };

  const addNewPermission = (e, index) => {
    if (!permissionState.find((item, i) => i === index)) {
      setPermissionState([...permissionState, permissionJson]);
    } else {
      setPermissionState((state) => state.map((item, i) => (i !== index ? item : e)));
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(permissionState, result.source.index, result.destination.index);
    setPermissionState([...items]);
  };

  const handleTag = async (e) => {
    if (e.code === "Enter" || e.code === "Comma") {
      e.preventDefault();
      if (tagList.findIndex((tg) => tg.toLowerCase() === tag.toLowerCase()) === -1) {
        const tags = [...tagList, tag];
        setTagList(tags);
        setTag("");
      }
    }
  };

  const removeTag = async (index) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
  };

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        onCancel={() => {
          props.setIsOpen(!isOpen);
          props.setEditId(null);
        }}
        onSuccess={() => {
          onSubmit();
          groupModalSubmit();
        }}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalDisabled,
          ...defaultOptions,
        }}
        defaultStyles={{
          ...defaultStyles,
          overFlowYVisible: false,
        }}>
        <div className="relative w-full space-y-4">
          <div className="relative w-full mb-4">
            <Input
              name="name"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {activeSSO.isSSOActive && (
            <div className="mb-4 space-y-4">
              <div className="w-full">
                <Input
                  type="text"
                  autoComplete="off"
                  name="tag-name"
                  label="Tags"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onKeyDown={handleTag}
                />
              </div>
              {tagList.length > 0 && (
                <div className="flex flex-wrap items-center gap-2">
                  {tagList.map((tag, index) => {
                    return (
                      <WorkspaceTag
                        index={index}
                        tag={tag}
                        onDelete={removeTag}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {!defaultWorkspaceId && (
            <div className="relative w-full mb-4">
              <Select
                label={"Workspace"}
                options={workspacesLoaded ? workspaces : []}
                selected={selectedWorkspace}
                setSelected={(e) => updateWorkspace(e)}
              />
            </div>
          )}

          <div className="relative w-full pt-1">
            <div className="relative w-full mb-4 border-b border-gray-200 pb-8">
              <H3 caption="Assign all users that should have access to these specific pages and links.">Assign users</H3>
              <MultiSelectObjectUsers
                usersLoaded={usersLoaded}
                users={users}
                selectedUserIds={selectedUser}
                handleSelectedUsers={handleSelectedUsers}
              />
            </div>
            {props.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && (
              <div>
                <div className="relative flex w-full mb-4">
                  <H3 caption="Assign all users that should have access to these specific pages and links.">Add pages</H3>
                  <div className="md:w-1/3 flex items-center justify-end">
                    <Button
                      disabled={pageList.length === 0}
                      version="secondary"
                      onClick={addNewPermission}>
                      Add Page
                    </Button>
                  </div>
                </div>
                <div className="max-h-96 overflow-x-visible overflow-y-scroll">
                  <WorkspaceGroupAuthorizeUserComponent
                    group={group}
                    onDragEnd={onDragEnd}
                    permissions={permissionState}
                    pageOptions={pages}
                    operatorOptions={operators}
                    setGroup={setGroup}
                    trustedAttributeArr={trustedAttributeArr}
                    valueType={valueType}
                    workspaceDetails={props.workspaceDetails}
                    workspaceId={props.workspaceDetails?._id}
                    viewOnly={false}
                    addPermission={(e, index) => {
                      addNewPermission(e, index);
                    }}
                    removePermission={(e, index) => {
                      setPermissionState((state) => state.filter((flt, i) => i !== index));
                    }}
                    authorizeUserComponentAccessPermission={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <WorkspaceImportUserModal
        isImportModalOpen={importModalOpen}
        setIsImportModalOpen={setImportModalOpen}
        selectedUsers={selectedUser}
        workspaceUsers={users}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        onSuccess={(users, status) => groupModalSubmit(status)}
        workspaceDetailsData={props.workspaceDetails}
        hideCrossIcon={false}
        disableStatus={successModalDisabled}
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { addGroup, editGroupData })(GroupModalAdd);
