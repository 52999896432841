import React from "react";
import SeoTitle from "src/components/Shared/Text/SeoTitle";
import AuthBackground from "src/assets/png/auth-background.png"

const AuthLayout = ({ children, ...props }) => {
  return (
    <>
      <SeoTitle title={props?.site.name} />
      <div style={{ 
          backgroundImage: `url(${AuthBackground})`, 
          backgroundSize: 'cover', // This ensures that the background covers the entire div
          backgroundPosition: 'center', // This centers the background image
          minHeight: '100vh', // Adjust the height as needed
          minWidth: '100vw', // Adjust the width as needed
        }} className="min-h-full flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8">{children}</div>
    </>
  );
};

export default AuthLayout;
