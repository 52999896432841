import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { logout } from "src/actions/auth";
import { getMenuData } from "src/actions/menu";

import AdminLayout from "src/layouts/AdminLayout";
import AuthLayout from "src/layouts/AuthLayout";
import UserLayout from "src/layouts/UserLayout";

import { getAuthorizedUser } from "src/actions/auth";
import useFetch from "src/hooks/useFetch";

const Layout = ({ logout, ...props }) => {
  const [routeLayout, setRouteLayout] = useState(<AuthLayout {...props}>{props.children}</AuthLayout>);

  const routeTypes = {
    auth: { routes: ["/forgot-password/submit-email", "/forgot-password/verify-token", "/forgot-password/password-reset", "/login", "/sso-logout", "/ai-graphing"], layout: <AuthLayout {...props}>{props.children}</AuthLayout> },
    error: { routes: ["/404", "/500", "/technical-issues"], layout: <>{props.children}</> },
  };

  const { response: authorizedUser, refreshData: refreshAuthorizedUser } = useFetch(
    `/users/get-details`,
    {
      method: "post",
      data: {
        id: localStorage.getItem("impersonatedUserId") || localStorage.getItem("userId") || props.user?._id,
        token: localStorage.getItem("refresh_token") || props.user?.refresh_token,
      },
    },
    localStorage.getItem("access_token") || props.user?.access_token
  );

  const userTypeRouteLayout = async () => {
    if (authorizedUser?.data?.type === "user") {
      setRouteLayout(<UserLayout {...props}>{props.children}</UserLayout>);
    } else {
      setRouteLayout(<AdminLayout {...props}>{props.children}</AdminLayout>);
    }
  };

  // useEffect(() => {
  //   if (!props.user) {
  //     // If the user isn't authenticated
  //     setRouteLayout(routeTypes.auth.layout);
  //     navigate("/login");
  //     return;
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.user, navigate]);

  useEffect(() => {
    refreshAuthorizedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  // useEffect(() => {
  //   const navigateIfNoId = () => {
  //     if (!props.site?._id) {
  //       navigate("technical-issues");
  //     }
  //   };
  //   navigateIfNoId();

  //   const timeoutId = setTimeout(navigateIfNoId, 2000);
  //   return () => clearTimeout(timeoutId);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.site?._id]);

  useEffect(() => {
    if (authorizedUser.status === 200) {
      userTypeRouteLayout(window.location.pathname);
      const inactivityLimit = 43200;
      let inactivityTime = 0;

      const resetInactivityTime = () => {
        inactivityTime = 0;
      };

      const incrementInactivityTime = () => {
        inactivityTime++;
        if (inactivityTime >= inactivityLimit) {
          logout(props.site);
        }
      };

      document.addEventListener("mousemove", resetInactivityTime);
      document.addEventListener("keydown", resetInactivityTime);
      document.addEventListener("click", resetInactivityTime);

      const interval = setInterval(incrementInactivityTime, 60000); // Check every minute
      return () => {
        clearInterval(interval);
      };
    } else {
      isAuthRouteLayout(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedUser]);

  const isAuthRouteLayout = (url) => {
    if ("/" === url) {
      setRouteLayout(routeTypes.auth.layout);
      return;
    }

    for (let type in routeTypes) {
      if (routeTypes[type]) {
        let foundRoute = routeTypes[type].routes.filter((route) => url?.includes(route));
        if (foundRoute.length > 0) {
          setRouteLayout(routeTypes[type].layout);
          return;
        }
      }
    }
  };

  return routeLayout;
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    menus: Object.values(state.menus),
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { getMenuData, logout, getAuthorizedUser })(Layout);
