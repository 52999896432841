import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Button from "src/components/Shared/Buttons/Button";
import { UsersIcon } from "@heroicons/react/24/outline";
import WorkspacePermissionTabDataItem from "src/components/Workspaces/Workspace/Permissions/WorkspacePermissionTabDataItem";
import Search from "src/components/Shared/Search";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import NoneFound from "src/components/Shared/NoData/NoneFound";

const WorkspacePermissionTabData = ({ workspaceId, workspaceDetails, userPermission, isAddUserModal, setIsAddUserModalOpen, userList, selectedTab, disabledColumns = [], handleSinglePermissionChange = () => {}, handleMultiplePermissionChanges = () => {} }) => {
  const [createCheckbox, setCreateCheckbox] = useState(false);
  const [updateCheckbox, setUpdateCheckbox] = useState(false);
  const [deleteCheckbox, setDeleteCheckbox] = useState(false);
  const [userSearchList, setUserSearchList] = useState([]);

  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (keyword) {
      setUserSearchList(userList.filter((user) => user.user_id.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())));
    } else {
      setUserSearchList(userList ? userList : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList, keyword]);

  useEffect(() => {
    if (userList && userList.length > 0) {
      let createCheckboxCount = 0;
      let updateCheckboxCount = 0;
      let deleteCheckboxCount = 0;
      for (let index = 0; index < userList.length; index++) {
        const element = userList[index];
        if (element[selectedTab + "_create"]) {
          createCheckboxCount++;
        }
        if (element[selectedTab + "_update"]) {
          updateCheckboxCount++;
        }
        if (element[selectedTab + "_delete"]) {
          deleteCheckboxCount++;
        }
      }
      if (userList.length === createCheckboxCount) {
        setCreateCheckbox(true);
      } else {
        setCreateCheckbox(false);
      }
      if (userList.length === updateCheckboxCount) {
        setUpdateCheckbox(true);
      } else {
        setUpdateCheckbox(false);
      }
      if (userList.length === deleteCheckboxCount) {
        setDeleteCheckbox(true);
      } else {
        setDeleteCheckbox(false);
      }
    }
  }, [selectedTab, userList]);

  return (
    <>
      <div className="relative w-full">
        <div className="flex flex-wrap mx-2">
          <div className="w-full flex justify-between px-2 mb-4">
            {userSearchList.length > 0 && (
              <Search
                placeholder={"Search"}
                keyword={keyword}
                setKeyword={setKeyword}
              />
            )}
            <div className="w-full flex justify-end items-center pr-4">
              {authorizeUserComponentAccess(userPermission, workspaceId, "permission", ["create", "delete"]) && (
                <Button
                  version="secondary"
                  onClick={() => {
                    setIsAddUserModalOpen(!isAddUserModal);
                  }}>
                  Manage
                  <UsersIcon className="w-5 h-5" />
                </Button>
              )}
            </div>
          </div>
        </div>
        {userSearchList.length === 0 && (
          <div className="h-full w-full p-4">
            <NoneFound />
          </div>
        )}
        <div className="flex gap-4 py-4 even:bg-slate-50 odd:bg-white">
        <div className="h-20 w-full max-w-[400px] min-w-[180px] md:min-w-[320px] flex items-center gap-3 px-4"/>
          {userSearchList.length > 0 && (
            <>
              <div className="flex flex-col gap-2 items-center w-16 sm:min-w-[64px]">
                <div className="text-xs uppercase text-slate-400">READ</div>
                <Checkbox
                  disabled
                  checkboxName={selectedTab + "_read"}
                  forceCheck
                  isChecked={createCheckbox || updateCheckbox || deleteCheckbox}
                  users={userList}
                />
              </div>
              <div className="flex flex-col gap-2 items-center w-16 sm:min-w-[64px]">
                <div className="text-xs uppercase text-slate-400">CREATE</div>
                {authorizeUserComponentAccess(userPermission, workspaceId, "permission", ["update"]) ? (
                  <Checkbox
                    disabled={disabledColumns.includes("create")}
                    checkboxName={selectedTab + "_create"}
                    isChecked={createCheckbox}
                    users={userList}
                    onChange={handleMultiplePermissionChanges}
                  />
                ) : (
                  <Checkbox
                    disabled={disabledColumns.includes("create")}
                    checkboxName={selectedTab + "_create"}
                    isChecked={createCheckbox}
                    users={userList}
                  />
                )}
              </div>
              <div className="flex flex-col gap-2 items-center w-16 sm:min-w-[64px]">
                <div className="text-xs uppercase text-slate-400">UPDATE</div>
                {authorizeUserComponentAccess(userPermission, workspaceId, "permission", ["update"]) ? (
                  <Checkbox
                    disabled={disabledColumns.includes("update")}
                    checkboxName={selectedTab + "_update"}
                    isChecked={updateCheckbox}
                    users={userList}
                    onChange={handleMultiplePermissionChanges}
                  />
                ) : (
                  <Checkbox
                    disabled={disabledColumns.includes("update")}
                    checkboxName={selectedTab + "_update"}
                    isChecked={updateCheckbox}
                    users={userList}
                  />
                )}
              </div>
              <div className="flex flex-col gap-2 items-center w-16 sm:min-w-[64px]">
                <div className="text-xs uppercase text-slate-400">DELETE</div>
                {authorizeUserComponentAccess(userPermission, workspaceId, "permission", ["update"]) ? (
                  <Checkbox
                    disabled={disabledColumns.includes("delete")}
                    checkboxName={selectedTab + "_delete"}
                    isChecked={deleteCheckbox}
                    users={userList}
                    onChange={handleMultiplePermissionChanges}
                  />
                ) : (
                  <Checkbox
                    disabled={disabledColumns.includes("delete")}
                    checkboxName={selectedTab + "_delete"}
                    isChecked={deleteCheckbox}
                    users={userList}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {userSearchList.length > 0 &&
          userSearchList.map((user, index) => {
            return (
              <WorkspacePermissionTabDataItem
                workspaceId={workspaceId}
                userPermission={userPermission}
                workspaceDetails={workspaceDetails}
                disabledColumns={disabledColumns}
                key={`${index}_${user?.user_id?._id}`}
                user={user}
                selectedTab={selectedTab}
                permissionChange={handleSinglePermissionChange}
              />
            );
          })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPermission: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, {})(WorkspacePermissionTabData);
