import { PlusCircleIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DisplayPageForm from "src/components/PageForm/DisplayPageForm";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import InformationHoverIcon from "src/components/Shared/InformationHoverIcon";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import { H3 } from "src/components/Shared/Text/Headers";
import TextCode from "src/components/Shared/Text/TextCode";
import { classNames } from "src/helpers/classNames";

const NestedPageContent = ({ newPage = {}, pages = [], setNewPage = () => {}, onDragEnd = () => {}, onAddPage = () => {}, onUpdatePage = () => {}, onRemovePage = () => {} }) => {
  const [showDatasetInput, setShowDatasetInput] = useState(false);

  const nestedPages = Array.isArray(newPage.nested_pages) ? newPage.nested_pages : [];

  useEffect(() => {
    if (!showDatasetInput && newPage.dataset_id) {
      setShowDatasetInput(true);
    }
  }, [showDatasetInput, newPage.dataset_id]);

  const handleDatasetShowToggle = () => {
    if (showDatasetInput) setNewPage((page) => ({ ...page, dataset_id: null }));
    setShowDatasetInput((cur) => !cur);
  };

  return (
    <div>
      <div className="flex justify-between items-end">
        <H3>Nested Pages</H3>
        <div className="mb-2">
          <Button
            version="gray"
            onClick={onAddPage}>
            <PlusIcon className="h-4 w-4" />
            Add page
          </Button>
        </div>
      </div>
      <div className="border border-gray-20 rounded py-2 px-5">
        {/* Nested Pages */}
        <div className="space-y-8 mt-3 mb-8">
          {newPage?.nested_pages?.length === 0 ? (
            <NoData />
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className={classNames(newPage?.nested_pages?.length > 1 ? "space-y-4" : "")}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {nestedPages.map((page, i) => (
                      <Draggable
                        key={i}
                        draggableId={`${i}`}
                        index={i}>
                        {(provided) => (
                          <DisplayPageForm
                            className={"!left-0"}
                            key={i}
                            index={i}
                            permission={page}
                            setPermission={(e) => onUpdatePage(e, i)}
                            pageOptions={pages}
                            removePermission={() => onRemovePage(i)}
                            innerRef={provided.innerRef}
                            draggableProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            showNestedData={false}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>

      {showDatasetInput ? (
        <div className="relative mt-5">
          <div className="top-1 left-2 absolute flex justify-center items-center h-10 w-6">
            <div className="h-[30px] w-[2px] bg-gray-200 rounded-full absolute top-[-14px] left-[2px]"></div>
            <div className="h-[2px] w-[16px] bg-gray-200 rounded-full absolute top-[16px] left-[2px]"></div>
            <div className="h-[2px] w-[12px] bg-gray-200 rounded-full rotate-45 absolute top-[12px] left-[8px]"></div>
            <div className="h-[2px] w-[12px] bg-gray-200 rounded-full -rotate-45 absolute top-[20px] left-[8px]"></div>
          </div>
          <div className="pl-10 py-1 flex flex-wrap items-center space-x-3 relative">
            <div className="flex items-center gap-x-1 relative text-sm font-medium text-gray-700">
              Only apply filters to
              <div className="inline-block relative">
                <TextCode text="Datasets" />
                <InformationHoverIcon location="-top-5 -right-5">List out comma seperated the dataset IDs.</InformationHoverIcon>
              </div>
              included in
            </div>
            <Input
              value={newPage.dataset_id || ""}
              onChange={(e) => setNewPage((page) => ({ ...page, dataset_id: e.target.value }))}
              placeholder="None"
            />
            <button
              type="button"
              onClick={handleDatasetShowToggle}
              className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-1 w-full">
          <button
            className="flex items-center"
            onClick={handleDatasetShowToggle}>
            <PlusCircleIcon className="h-4 w-4 mr-1" /> <span>Add Datasets</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default NestedPageContent;
