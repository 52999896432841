import { Menu } from "@headlessui/react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "src/actions/auth";
import { classNames } from "src/helpers/classNames";

function AdminNavDesktop({ userNavigation, logout }) {
  const navigate = useNavigate();
  return (
    <>
      {userNavigation.map((item, site, updateNavigation) => (
        <Menu.Item key={item.name}>
          {({ active }) => (
            <Fragment key={item.name}>
              {item.name !== "Sign out" ? (
                <div onClick={(navItem) => (updateNavigation.includes(navItem.name) ? navigate(navItem.name) : null)}>
                  {item.path.includes("http") ? (
                    <a
                      href={item.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classNames(active ? "bg-color-06 bg-gray-100" : "", "flex px-4 py-2 text-sm text-gray-700 hover:text-highlightColor")}>
                      {item.name} {item.icon && <div className="flex pl-2">{item.icon}</div>}
                    </a>
                  ) : (
                    <Link
                      to={item.path}
                      className={classNames(active ? "bg-color-06 bg-gray-100" : "", "flex px-4 py-2 text-sm text-gray-700 hover:text-highlightColor")}>
                      {item.name} {item.icon && <div className="flex pl-2">{item.icon}</div>}
                    </Link>
                  )}
                </div>
              ) : (
                <>
                  <hr className="py-.5 mt-1" />
                  <button
                    onClick={(e) => {
                      logout(site);
                      e.preventDefault();
                    }}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:text-highlightColor">
                    {item.name}
                  </button>
                </>
              )}
            </Fragment>
          )}
        </Menu.Item>
      ))}
    </>
  );
}

export default connect(null, { logout })(AdminNavDesktop);
