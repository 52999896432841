import React from "react";
import { noLogo } from "src/config/host";
import AdminPortalLogo from "src/assets/png/admin-portal-logo.png";

const AuthContainer = ({ children, site }) => {
  return (
    <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-lg">
      <div className="bg-white shadow-md rounded-3xl min-h-[183px] overflow-hidden">
        <div className="bg-headerColor p-2 m-5 rounded-xl">
          <a href="#!">
            {site?.image_logo && (
              <div>
                <img
                  className="mx-auto h-auto max-h-[80px] py-2 w-auto max-w-[80%]"
                  src={AdminPortalLogo}
                  alt="Logo"
                  onError={(e) => (e.target.src = `${noLogo}`)}
                />
                <img
                  className="mx-auto h-auto max-h-[80px] py-2 w-auto max-w-[80%]"
                  src={`${site?.image_logo}?${Date.now()}`}
                  alt="Logo"
                  onError={(e) => (e.target.src = `${noLogo}`)}
                />
              </div>
            )}
          </a>
        </div>
        <div className="px-4 py-8 sm:px-10">{children}</div>
      </div>
    </div>
  );
};

export default AuthContainer;
