import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { manageSiteSettings } from "src/actions/site";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { toast } from "react-toastify";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import Section from "src/components/Shared/Containers/Section";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";

const BetaHeader = ({ title, horizontalRow = true }) => {
  return (
    <div className="grid gap-y-4">
      {horizontalRow && <hr className="mb-5" />}
      <div className="mb-4 flex w-full items-center justify-between">
        <h3 className="pb-1 text-xl font-semibold text-gray-500">
          {title} <span className="text-gray-300 font-normal">(BETA)</span>
        </h3>
      </div>
    </div>
  );
};

const PrivacyAndSecurity = ({ site, manageSiteSettings }) => {
  const [userDeactivationEnabled, setUserDeactivationEnabled] = useState(false);
  const [userDeactivationTimeframe, setUserDeactivationTimeframe] = useState("");
  const [userDeletionEnabled, setUserDeletionEnabled] = useState(false);
  const [userDeletionTimeframe, setUserDeletionTimeframe] = useState("");
  const [userInactivityLogoutEnabled, setUserInactivityLogoutEnabled] = useState(site?.user_inactivity_logout_enabled || false);
  const [userInactivityLogoutTimeframe, setUserInactivityLogoutTimeframe] = useState(site?.user_inactivity_logout_timeframe || "");
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(site?.two_factor_enabled || false);
  const [twoFactorTimeframe, setTwoFactorTimeframe] = useState(site?.two_factor_timeframe || "");
  const [isLoading, setIsLoading] = useState(false);
  const [onChangeDisable, setOnChangeDisable] = useState(true);
  const [checkboxDefaultCustomField, setCheckboxDefaultCustomField] = useState(false);
  const [defaultCustomFieldValue, setDefaultCustomFieldValue] = useState("");

  const setPageData = (site) => {
    if (site?._id) {
      setUserDeactivationEnabled(site.user_deactivation_enabled || false);
      setUserDeactivationTimeframe(site.user_deactivation_timeframe || "");
      setUserDeletionEnabled(site.user_deletion_enabled || false);
      setUserDeletionTimeframe(site.user_deletion_timeframe || "");
      setUserInactivityLogoutEnabled(site.user_inactivity_logout_enabled || false);
      setUserInactivityLogoutTimeframe(site.user_inactivity_logout_timeframe || "");
      setTwoFactorEnabled(site.two_factor_enabled || false);
      setTwoFactorTimeframe(site.two_factor_timeframe || "");
    }
  };

  useEffect(() => {
    setPageData(site);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const message = await manageSiteSettings({
        user_deactivation_enabled: userDeactivationEnabled,
        user_deactivation_timeframe: userDeactivationTimeframe,
        user_deletion_enabled: userDeletionEnabled,
        user_deletion_timeframe: userDeletionTimeframe,
        user_inactivity_logout_enabled: userInactivityLogoutEnabled,
        user_inactivity_logout_timeframe: userInactivityLogoutTimeframe,
        two_factor_enabled: twoFactorEnabled,
        two_factor_timeframe: twoFactorTimeframe,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      setOnChangeDisable(true);
    }
  };

  return (
    <Section>
      <div className="w-full grid gap-y-10">
        {false && (
          <div className="relative w-full mb-5 space-y-12">
            <div className="w-full mt-6">
              <BetaHeader
                title="Add Custom Fields"
                horizontalRow={false}
              />
              <div className="relative w-full flex flex-col max-w-sm gap-y-5">
                <div>
                  <Input label="Label" />
                </div>
                {/* <div className="grid gap-y-2">
                  <div className="flex items-center gap-x-3">
                    <Checkbox
                      checkboxName="set_default"
                      onChange={() => setCheckboxDefaultCustomField(!checkboxDefaultCustomField)}
                      isChecked={checkboxDefaultCustomField}
                    />
                    <p className="text-gray-500">*Is a required field</p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Checkbox
                      checkboxName="set_default"
                      onChange={() => setCheckboxDefaultCustomField(!checkboxDefaultCustomField)}
                      isChecked={checkboxDefaultCustomField}
                    />
                    <p className="text-gray-500">Add a default value</p>
                  </div>
                  <div>
                    <Input
                      disabled={!checkboxDefaultCustomField}
                      label="Default"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}
        <div>
          <BetaHeader
            title="Inactivity Manager"
            horizontalRow={false}
          />
          <div className="flex flex-col gap-y-12">
            <div className="relative w-full">
              <ToggleHeader
                title="Inactivity auto-logout"
                subtitle="Define how long a user may be inactive.">
                <Toggle
                  checked={userInactivityLogoutEnabled}
                  onChange={(e) => {
                    setOnChangeDisable(false);
                    setUserInactivityLogoutEnabled(e);
                  }}
                />
              </ToggleHeader>
              <div className="font-light flex px-8 w-full items-center gap-y-3 gap-x-5">
                <p>Log a user out after</p>
                <div className="w-[70px]">
                  <Input
                    type="number"
                    name="auto-logout"
                    disabled={!userInactivityLogoutEnabled}
                    value={userInactivityLogoutTimeframe}
                    onChange={(e) => {
                      setOnChangeDisable(false);
                      setUserInactivityLogoutTimeframe(e.target.value);
                    }}
                  />
                </div>
                <p>minutes spent inactive.</p>
              </div>
            </div>
            <div className="relative w-full">
              <ToggleHeader
                title="Automatic User Deactivation"
                subtitle="(Excluding admins)">
                <Toggle
                  checked={userDeactivationEnabled}
                  onChange={(e) => {
                    setOnChangeDisable(false);
                    setUserDeactivationEnabled(e);
                  }}
                />
              </ToggleHeader>
              <div className="font-light flex px-8 w-full items-center gap-y-3 gap-x-5">
                <p>
                  <span className="font-semibold">DEACTIVATE</span> users after
                </p>
                <div className="w-[70px]">
                  <Input
                    name="user-deactivation"
                    type="number"
                    disabled={!userDeactivationEnabled}
                    value={userDeactivationTimeframe}
                    onChange={(e) => {
                      setOnChangeDisable(false);
                      setUserDeactivationTimeframe(e.target.value);
                    }}
                  />
                </div>
                <p>days without a login.</p>
              </div>
            </div>

            <div className="relative w-full">
              <ToggleHeader
                title="Automatic User Deletion"
                subtitle="(Excluding admins)">
                <Toggle
                  checked={userDeletionEnabled}
                  onChange={(e) => {
                    setOnChangeDisable(false);
                    setUserDeletionEnabled(e);
                  }}
                />
              </ToggleHeader>
              <div className="font-light flex px-8 w-full items-center gap-y-3 gap-x-5">
                <p>
                  Permanently <span className="font-semibold">DELETE</span> users after
                </p>
                <div className="w-[70px]">
                  <Input
                    name="user-deletion"
                    type="number"
                    disabled={!userDeletionEnabled}
                    value={userDeletionTimeframe}
                    onChange={(e) => {
                      setOnChangeDisable(false);
                      setUserDeletionTimeframe(e.target.value);
                    }}
                  />
                </div>
                <p>days without a login.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative w-full mb-5 space-y-12">
          <div className="w-full mt-6">
            <BetaHeader title="2FA-protected" />
            <div className="relative w-full">
              <ToggleHeader
                title="Require email accounts to be 2FA"
                subtitle="Set if devices can be remembered and for how long.">
                <Toggle
                  checked={twoFactorEnabled}
                  onChange={(e) => {
                    setOnChangeDisable(false);
                    setTwoFactorEnabled(e);
                  }}
                />
              </ToggleHeader>
              <div className="font-light flex px-8 w-full items-center gap-y-3 gap-x-5">
                <p>2FA is required after</p>
                <div className="w-[70px]">
                  <Input
                    type="number"
                    name="2fa-require"
                    disabled={!twoFactorEnabled}
                    value={twoFactorTimeframe}
                    onChange={(e) => {
                      setOnChangeDisable(false);
                      setTwoFactorTimeframe(e.target.value);
                    }}
                  />
                </div>
                <p>days without a login.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end gap-x-4">
        <Button
          disabled={isLoading || onChangeDisable}
          onClick={onSubmit}>
          Save{isLoading && <ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" />}
        </Button>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(PrivacyAndSecurity);
