import { EnvelopeIcon, KeyIcon, RectangleStackIcon, TrashIcon, UserMinusIcon, UsersIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteUser, getMenuUserList } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Buttons/Button";
import DataExport from "src/components/Shared/DataExport";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import MultiRowSelectButton from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectButton";
import MultiRowSelectContainer from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectContainer";
import { Tooltip as ReactTooltip } from "react-tooltip";

import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import UserEntry from "src/components/Users/List/UserEntry";
import { apiUrl } from "src/config/host";
import useFetch from "src/hooks/useFetch";
import MultiRowSelectedCountBubble from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectedCountBubble";

const UsersList = ({ me, title = "", filterUserType = [], ...props }) => {
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);

  const [group] = useState("");
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [disableActions, setDisableActions] = useState(false);
  const [isDeleteMultiUserModalOpen, setIsDeleteMultiUserModal] = useState(false);

  const navigate = useNavigate();

  const {
    response: { data: users, meta },
    status: { done: usersLoaded },
    refreshData: refreshUsers,
  } = useFetch("/users", { query: { type: filterUserType + "", limit: limit || 20, offset: offset || 0, keyword: keyword || "" } });

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, keyword]);

  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest("post", "/workspaces/list", {
        body: {
          includes: ["groups"],
        },
      });
      const data = res.data;
      if (data.status === 200) {
        setAllWorkspaces(data.data);
      }
    };
    loadWorkspaces();
  }, []);

  const removeUser = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setIsDisableSubmit(true);
      const message = await props.deleteUser({
        users: [deleteId],
      });
      setDeleteId(null);
      refreshUsers();
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisableSubmit(false);
    }
  };

  const prepData = async () => {
    const params = { group: group, keyword: keyword };
    if (filterUserType.length) params.filterTypes = filterUserType.toString();
    try {
      const res = await fetchData("GET", `${apiUrl}/users?type=user,editor`, params);
      const { data } = await res.json();

      let exportDoc = [];
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let groupName = [];
          if (element.groups.length > 0) {
            for (let i = 0; i < element.groups.length; i++) {
              const grup = element.groups[i];
              groupName.push(grup.name);
            }
          }
          exportDoc.push({
            Name: element?.name,
            Email: element?.email,
            Type: element?.type,
            Groups: groupName.length > 0 ? groupName?.toString() : "",
            "Date Created": moment(new Date(element?.created_at)).format("MM/DD/YYYY HH:mm:ss"),
            "Last Login": element?.last_login ? moment(new Date(element?.last_login)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
            "Is Active": element?.active_status ? "true" : "false",
            "Deactivation Dated": !element?.active_status ? moment(new Date(element?.deactivated_at)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
          });
        }
      }
      return exportDoc;
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const inviteMultiUser = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", "/users/send-reset-email", { body: { userIds: checkedUsers } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableActions(false);
    }
  };

  const assignWorkspaceUsers = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", "/users/workspace-assign", { body: { workspaces: selectedWorkspaces, userIds: checkedUsers } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        setSelectedWorkspaces([]);
        setWorkspaceModalOpen(false);
        toast.success(res.data.message);

        refreshUsers();
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableActions(false);
    }
  };

  const deactivateMultiUser = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", "/users/manage-status", { body: { id: checkedUsers } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        refreshUsers();

        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableActions(false);
    }
  };

  const deleteMultiuser = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", "/users/remove", { body: { users: checkedUsers } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        setIsDeleteMultiUserModal(false);
        refreshUsers();

        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableActions(false);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0);
              setKeyword(val);
            }}
            clearIcon={true}
          />
        }
        middleContent={
          <>
            {/* <SelectMenu
              options={[
                { key: "All Status", value: "" },
                { key: "Active", value: "true" },
                { key: "Inactive", value: "false" },
              ]}
              setOption={(option) => {
                if (option.value) {
                  setStatus(option.value === "true");
                } else {
                  setStatus(option.value);
                }
              }}
            /> */}
            {me?.type === "admin" && (
              <MultiRowSelectContainer disabled={checkedUsers?.length === 0}>
                <MultiRowSelectButton
                  name="Resend welcome invites"
                  disabled={checkedUsers?.length === 0}
                  onClick={
                    // () => {}
                    inviteMultiUser
                  }
                  Icon={EnvelopeIcon}
                />
                <MultiRowSelectButton
                  name="Assign to workspace"
                  disabled={checkedUsers?.length === 0}
                  onClick={() => setWorkspaceModalOpen(true)}
                  component={
                    <IconsWithPlus
                      size={checkedUsers?.length === 0 ? "h-6 stroke-slate-400" : ` h-6 stroke-slate-400 group-hover:stroke-${props?.site?.highlight_color ? 'highlightColor' : 'gray-500'}`}
                      strokeColor={checkedUsers?.length === 0 ? "stroke-slate-400" : `stroke-slate-400 group-hover:stroke-${props?.site?.highlight_color ? 'highlightColor' : 'gray-500'}`}
                      item={{ icon: RectangleStackIcon }}
                    />
                  }
                />
                <span className="text-gray-300 px-2">|</span>
                <MultiRowSelectButton
                  name="Deactivate users"
                  disabled={checkedUsers?.length === 0}
                  onClick={deactivateMultiUser}
                  Icon={UserMinusIcon}
                />
                {props?.site?.api_state !== "DOMO_DATASET" && (
                  <MultiRowSelectButton
                    name="Delete users"
                    disabled={checkedUsers?.length === 0}
                    onClick={() => setIsDeleteMultiUserModal(true)}
                    Icon={TrashIcon}
                  />
                )}
              </MultiRowSelectContainer>
            )}
          </>
        }
        rightContent={
          props?.site?.api_state !== "DOMO_DATASET" &&
          me?.type === "admin" && (
            <Button
              version="secondary"
              hoverText={`Add a${title === "Admin" ? "n administrator" : " user"}`}
              onClick={() => navigate(title === "Admin" ? `/admins/add` : `/users/add`)}>
              <IconsWithPlus
                strokeColor={"stroke-highlightColor"}
                item={{ icon: title === "Admin" ? KeyIcon : UsersIcon }}
              />
            </Button>
          )
        }></TableOptions>
      <Table
        tableHeader={
          <TRHeader>
            {me?.type === "admin" && (
              <th
                scope="col"
                className="relative rounded-tl-lg w-12 whitespace-nowrap py-3 pl-1 text-sm sm:pl-4">
                <ReactTooltip
                  id={`user_list_select_all`}
                  delayShow={200}
                  positionStrategy="fixed"
                  className="z-[200] opacity-100 bg-gray-700 rounded px-2 py-2">
                  <div className="font-normal leading-[10px]">Select all users shown</div>
                </ReactTooltip>
                <MultiRowSelectedCountBubble
                  count={checkedUsers.length}
                  clear={() => setCheckedUsers([])}
                  position="-top-[6px] left-[28px]"
                />
                <div data-tooltip-id="user_list_select_all">
                  <Checkbox
                    isChecked={checkedUsers.length && checkedUsers.length === users.length}
                    onChange={() => {
                      if (checkedUsers.length === users.length) {
                        setCheckedUsers([]);
                      } else {
                        setCheckedUsers(users.map((user) => user._id));
                      }
                    }}
                  />
                </div>
              </th>
            )}

            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Email
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th
              scope="col"
              className="rounded-tr-lg relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="4"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={usersLoaded}
        dataExists={users?.length > 0}>
        {users.map((user, i) => {
          return (
            <UserEntry
              key={user._id}
              user={user}
              deleteId={deleteId}
              setDeleteId={setDeleteId}
              refreshUsers={refreshUsers}
              lastRow={i === users.length - 1}
              allowMulti
              checkedUsers={checkedUsers}
              setCheckedUsers={setCheckedUsers}
              disableCheckButton={disableActions}
            />
          );
        })}
      </Table>
      <PaginationFooter
        itemName={title === "Admin" ? `Admin` : `User`}
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />
      {me?.type !== "user" && (
        <DataExport
          title="user_list_report"
          headings={["Name", "Email", "Type", "Groups", "Date Created", "Last Login", "Is Active", "Deactivated Date"]}
          prepData={prepData}
        />
      )}

      <Modal
        title="User"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={removeUser}
        isLoading={isDisableSubmit}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {users?.length && users.find((user) => user._id === deleteId)?.name}?</div>
      </Modal>
      {me?.type === "admin" && (
        <Modal
          title="Users"
          secondaryTitle="Delete"
          isOpen={isDeleteMultiUserModalOpen}
          onCancel={() => setIsDeleteMultiUserModal(false)}
          onSuccess={deleteMultiuser}
          isLoading={disableActions}
          defaultOptions={{
            onSuccessButtonText: "Delete",
          }}>
          <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete the selected {checkedUsers.length > 1 ? <span className="font-bold">{checkedUsers.length} users</span> : "user"}?</div>
        </Modal>
      )}
      {me?.type === "admin" && (
        <Modal
          title={`Assign Workspace`}
          isOpen={workspaceModalOpen}
          onCancel={() => {
            setWorkspaceModalOpen(false);
            setSelectedWorkspaces([]);
          }}
          onSuccess={assignWorkspaceUsers}
          defaultStyles={{
            overFlowYVisible: true,
          }}
          defaultOptions={{
            onSuccessLoaderStart: disableActions,
            onSuccessLoaderVisible: disableActions,
          }}>
          <div className="relative min-h-[200px] py-4">
            <MultiSelectObject
              defaultOptions={allWorkspaces.map((workspace) => {
                return { key: workspace._id, value: workspace.name, selected: selectedWorkspaces.includes(workspace?._id), object: workspace };
              })}
              searchableFields={["name"]}
              title="Assign workspace"
              onChange={(workspacesArray) => {
                let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
                setSelectedWorkspaces(updatedSelectedWorkspaces);
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  getMenuUserList,
  deleteUser,
})(UsersList);
