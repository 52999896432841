import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAuthorizedUser } from "src/actions/auth";
import { clearUserDetails, manageUserStatus } from "src/actions/user";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import QuickMenu from "src/components/Shared/QuickMenu";
import TableRow from "src/components/Shared/Table/TableRow";
import UserProfile from "src/components/Users/UserProfile";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";

const UserEntry = ({ user, clearUserDetails, manageUserStatus, getPages, setDeleteId, lastRow, me, site, getAuthorizedUser, allowMulti = false, disableCheckButton = false, checkedUsers = [], setCheckedUsers = () => {}, refreshUsers = () => {}, manageUserRole = () => {}, workspaceDetails = {}, removeUserAccess = () => {}, ...props }) => {
  const navigate = useNavigate();
  const URLParams = useParams();

  const formatMemberDate = (date) => {
    if (!date) return "";
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  const roles = [
    { key: "Participant", value: "participant" },
    { key: "Editor", value: "editor" },
    { key: "Privileged", value: "privileged" },
    { key: "Admin", value: "admin" },
  ];

  const [activeStatus, setActiveStatus] = useState(true);

  useEffect(() => {
    setActiveStatus(user.active_status);
  }, [user.active_status]);

  const insertTypes = {
    SSO_LOGIN: "SSO",
    MANUAL_INPUT: "Manually",
    BULK_UPLOAD: "Bulk",
    DOMO_DATASET: "Dataset",
  };

  const setPreviewUserId = async (id) => {
    localStorage.setItem("impersonatedUserId", id);
    const ac = new AbortController();
    await getAuthorizedUser(ac.signal);
    navigate("/workspaces/menu");
  };

  const navigateUserMenu = (menuOption) => {
    navigate(user?.type === "admin" ? `/admins/${user._id}?tab=${menuOption}` : `/users/${user._id}?tab=${menuOption}`);
  };

  const isWorkspaceRoute = window.location.href.includes("workspaces") && URLParams?.id;

  return (
    <TableRow
      keyId={user?._id}
      lastRow={lastRow}>
      {
        allowMulti && me?.type === 'admin' &&
        <td className="whitespace-nowrap py-3 pl-1 text-sm sm:pl-4">
          <Checkbox 
            isChecked={checkedUsers.includes(user._id)}
            onChange={() => {
              if (disableCheckButton) return
              setCheckedUsers(cur => {
                if (cur.includes(user._id)) {
                  return cur.filter(item => item !== user._id)
                } else {
                  return [ ...cur, user._id ]
                }
              })
            }}
          />
        </td>
      }
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm">
        <div className="flex items-center">
          <div className="hidden h-10 w-10 flex-shrink-0 overflow-hidden rounded-full sm:block">{<UserProfile user={user || {}} />}</div>
          <div className="sm:ml-4">
            <div className="flex items-center gap-x-2 font-medium text-gray-900">
              {authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]) ? (
                <Link
                  to={user?.type === "admin" ? `/admins/${user._id}` : `/users/${user._id}`}
                  className="text-gray-900 font-medium cursor-pointer hover:underline">
                  {user?.name}
                </Link>
              ) : (
                user?.name
              )}
              {user?.default_user && <span className="rounded-full bg-gray-100 px-2 py-[1px] text-gray-400">Admin</span>}
            </div>

            <div className="text-gray-500">
              {insertTypes[user.creation_type]} created {formatMemberDate(user.created_at)}
            </div>
          </div>
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell">
        <div className="text-gray-900">{user?.email}</div>
        <div className="text-gray-500">{user?.username}</div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 lg:table-cell">
        <button
          onClick={async () => {
            await manageUserStatus(user);
            refreshUsers(user);
            setActiveStatus(!activeStatus);
          }}
          className={classNames("flex w-[52px] justify-center rounded-full px-2 text-xs font-semibold leading-5", activeStatus ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500", me?.type !== "admin" ? "pointer-events-none cursor-default" : "")}>
          {activeStatus ? "Active" : "Inactive"}
        </button>
      </td>
      {workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
        <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 lg:table-cell">
          <SelectMenu
            options={roles}
            setOption={(e) => manageUserRole(e, user)}
            startIndex={roles.findIndex((rl) => rl.value === user.type)}
          />
        </td>
      )}
      <td className="relative flex justify-end whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium sm:pr-6">
        <QuickMenu
          items={[
            {
              name: "View Profile",
              hideWhen: (user.type === "admin" && me?.type !== "admin") || !authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]),
              onClick: () => {
                clearUserDetails(null);
                navigateUserMenu("View+Profile");
              },
            },
            {
              name: "Workspaces",
              onClick: () => {
                clearUserDetails(null);
                navigateUserMenu("Workspaces");
              },
            },
            {
              name: "Permissions",
              hideWhen: user?.type === "admin",
              onClick: () => {
                clearUserDetails(null);
                navigateUserMenu("Permissions");
              },
            },
            {
              name: "Data Access",
              onClick: () => {
                clearUserDetails(null);
                navigateUserMenu("Data+Access");
              },
            },
            {
              hideWhen: user.default_user,
              type: "hr",
            },
            {
              name: `Impersonate`,
              hideWhen: ["admin", "editor"].includes(user?.type),
              isBeta: true,
              onClick: () => {
                setPreviewUserId(user._id);
              },
            },
            {
              name: "Remove Access",
              hideWhen: me?.type !== "admin" || !isWorkspaceRoute || !authorizeUserComponentAccess(me, workspaceDetails?._id, "permission", ["delete"]),
              isBeta: false,
              onClick: () => removeUserAccess(user._id),
            },
            {
              name: "Delete",
              hideWhen:
                user.default_user ||
                (user?.type === "admin" && workspaceDetails?._id) ||
                site.api_state === "DOMO_DATASET" ||
                !authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["delete"]) ||
                (me?.type !== "admin" && user?.type === "admin") ||
                me?._id === user?._id,
              onClick: () => {
                setDeleteId(user._id);
              },
              color: "text-red-500",
            },
          ]}
        />
      </td>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { clearUserDetails, manageUserStatus, getAuthorizedUser })(UserEntry);
