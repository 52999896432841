import { TrashIcon } from "@heroicons/react/24/outline";
import { Form, Formik } from "formik";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import Select from "src/components/Shared/Forms/FormikForms/Select";
import * as Yup from "yup";
import { connect } from "react-redux";
import Equals from "src/assets/svgs/operators/equals.svg";
import NotEqual from "src/assets/svgs/operators/not-equal.svg";
import GreaterThan from "src/assets/svgs/operators/greater-than.svg";
import GreaterThanOrEqual from "src/assets/svgs/operators/greater-than-equal.svg";
import LessThan from "src/assets/svgs/operators/less-than.svg";
import LessThanOrEqual from "src/assets/svgs/operators/less-than-equal.svg";
import { useEffect, useState } from "react";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

let conversionTableSVG = {
  EQUALS: Equals,
  NOT_EQUALS: NotEqual,
  GREATER_THAN: GreaterThan,
  GREATER_THAN_EQUALS_TO: GreaterThanOrEqual,
  LESS_THAN: LessThan,
  LESS_THAN_EQUALS_TO: LessThanOrEqual,
};

let conversionTableText = {
  IN: "IN",
  NOT_IN: "NOT IN",
  CONTAINS: "CONTAINS"
};

const DisplayPageCondition = ({ condition = {}, operatorOptions = [], setCondition = () => {}, removeCondition = () => {}, trustedAttributeArr, valueType, ...props }) => {
  const [valueTypeId, setValueTypeId] = useState({ _id: "value", name: "Text Value" });
  const [trustedAttributeId, setTrustedAttributeId] = useState({ _id: -1, name: "" });
  const [operatorList, setOperatorList] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState({ _id: -1, name: "" });
  useEffect(() => {
    setOperatorList(
      operatorOptions.map((operatorOption) => ({
        _id: operatorOption._id,
        code: operatorOption.code,
        name: operatorOption.name,
        value: (
          <div className="">
            {conversionTableSVG[operatorOption.name] ? (
              <img
                className="h-5 w-auto m-0 p-0"
                src={conversionTableSVG[operatorOption.name]}
                alt="operator"
              />
            ) : (
              <p className="subpixel-antialiased font-semibold text-lg min-w-40">{conversionTableText[operatorOption.name]}</p>
            )}
          </div>
        ),
      }))
    );
  }, [operatorOptions]);

  useEffect(() => {
    let index = operatorList.findIndex((op) => op._id === condition?.operator_id);
    if (index !== -1) {
      setSelectedOperator(operatorList[index]);
    }
  }, [operatorList, condition?.operator_id]);

  useEffect(() => {
    const index = valueType.findIndex((item) => item._id === condition.value_type);
    if (index !== -1) {
      setValueTypeId(valueType[index]);
      // setValueTypeOption(valueType.filter((v) => v._id !== condition.value_type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition.value_type]);

  useEffect(() => {
    const index = trustedAttributeArr.findIndex((item) => item._id === condition.trusted_attribute);
    if (index !== -1) {
      setTrustedAttributeId(trustedAttributeArr[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition.trusted_attribute]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        column_name: condition?.column_name || "",
        operator_id: condition?.operator_id || "",
        column_value: condition?.column_value || "",
        trusted_attribute: condition?.trusted_attribute || "",
      }}
      validationSchema={Yup.object({
        column_name: Yup.string().required("Required"),
        operator_id: Yup.string().required("Required"),
        column_value: Yup.string().required("Required"),
      })}>
      {({ values, errors, touched }) => {
        const handleOnChange = (e) => {
          setCondition({
            ...condition,
            [e.target.name]: e.target.value,
          });
        };
        return (
          <Form onChange={handleOnChange}>
            <div className="relative flex bg-gray-50 border border-gray-200 rounded-md w-full">
              <div className="w-full flex flex-col sm:flex-row sm:items-center pb-3 pt-1 px-4 gap-4">
                <div className="w-full">
                  <Input
                    label="Column Name"
                    name="column_name"
                    placeholder="Column"
                    value={condition.column_name}
                  />
                </div>
                <div className="">
                  <Select
                    label="Operator"
                    name="operator_id"
                    selected={selectedOperator}
                    onChange={(e) => {
                      setCondition({
                        ...condition,
                        operator_id: e._id,
                      });
                    }}
                    options={operatorList}
                  />
                </div>
                <div className="w-full">
                  <Select
                    name="trusted_attribute"
                    options={valueType}
                    selected={valueTypeId}
                    selectType="label"
                    onChange={(e) => {
                      setCondition({
                        ...condition,
                        value_type: e._id,
                        column_value: "",
                        trusted_attribute: "",
                      });
                      setTrustedAttributeId({ _id: -1, name: "" });
                    }}
                  />
                  {condition?.value_type === "attribute" ? (
                    <Select
                      name="column_value"
                      options={trustedAttributeArr}
                      selected={trustedAttributeId}
                      onChange={(e) => {
                        setCondition({
                          ...condition,
                          trusted_attribute: e._id,
                        });
                      }}
                    />
                  ) : (
                    <Input
                      name="column_value"
                      placeholder="Value"
                      value={condition.column_value}
                    />
                  )}
                </div>
              </div>
              {!props?.viewOnly && authorizeUserComponentAccess(props.me, props?.workspaceId, "group", ["update"]) &&
                <button
                  type="button"
                  onClick={removeCondition}
                  className="rounded-r-md w-10 min-w-[40px] flex items-center justify-center border-l border-gray-200 text-gray-400 hover:text-gray-500 outline-none ring-0 bg-gray-50/50 hover:bg-gray-100 transition-all duration-150">
                  <TrashIcon className="w-5 h-5 stroke-2" />
                </button>
              }
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user
  };
};

export default connect(mapStateToProps, {})(DisplayPageCondition);