import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import FormContainer from "src/components/Shared/Containers/FormContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import RadioGroup from "src/components/Shared/Forms/Inputs/RadioGroup";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import ProfileImage from "src/components/Users/Edit/ViewProfile/ProfileImage";
import { classNames } from "src/helpers/classNames";

const AddUserForm = ({ index, state, setState, isAdmin, groups, workspaces = [], setWorkspaces = () => {}, disabled = false, ...props }) => {
  const [groupChangeKey, setGroupChangeKey] = useState(new Date().getTime());
  const [selectedGroups, setSelectedGroups] = useState([]);

  const removeForm = (e) => {
    e.preventDefault();

    delete state[index];
    setState(state);

    const availableForms = props.formCount.filter((count) => count !== index);
    props.setFormCount(availableForms);
  };

  const getValue = (key) => (state[index] ? state[index][key] : "");

  const updateState = (value, key) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        [key]: value,
      },
    });
  };

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        image: "",
        type: isAdmin ? "admin" : "user",
        notifications: false,
        update: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let updatedGroups = groups.filter((group) => selectedGroups.includes(group._id || group.id));
    if (state[index]) {
      setState({
        ...state,
        [index]: {
          ...state[index],
          groups: updatedGroups,
        },
      });
    } else {
      setState({
        ...state,
        [index]: {
          ...state[index],
          name: "",
          email: "",
          password: "",
          confirm_password: "",
          image: "",
          type: isAdmin ? "admin" : "user",
          notifications: false,
          update: true,
          groups: updatedGroups,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  const handleSelectedGroups = (groups, singleGroup) => {
    let filteredGroups = groups.filter((group) => group.selected);
    setSelectedGroups(filteredGroups.map((group) => group.key));
  };

  useEffect(() => {
    if (state[index]?.update) {
      setState({
        ...state,
        [index]: {
          ...state[index],
          password: "",
          confirm_password: "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.update]);

  useEffect(() => {
    setGroupChangeKey(new Date().getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.filter((item) => item.selected).length]);

  return (
    <FormContainer>
      {index !== 0 && (
        <button
          type="button"
          className="absolute top-4 right-4 bg-white rounded-md text-red-400 hover:text-red-500 focus:outline-none"
          onClick={removeForm}>
          <span className="sr-only">Close</span>
          <XMarkIcon
            className="h-6 w-6"
            aria-hidden="true"
          />
        </button>
      )}

      <div className="flex flex-wrap w-full gap-y-4">
        {/* Photo */}
        <div className="w-full min-w-[200px] px-2">
          <ProfileImage
            user={state[index]}
            image={state[index]?.image}
            setImage={(value) => updateState(value, "image")}
          />
        </div>

        {/* Name */}
        <div className="sm:w-1/2 w-full px-2">
          <Input
            autocomplete="off"
            name="full-name"
            label="Full name"
            type="text"
            value={getValue("name")}
            onChange={(e) => updateState(e.target.value, "name")}
          />
        </div>

        {/* Email */}
        <div className="sm:w-1/2 w-full px-2">
          <Input
            name="email"
            label="Email"
            value={getValue("email")}
            onChange={(e) => updateState(e.target.value, "email")}
          />
        </div>

        <div className="sm:w-1/2 w-full px-2">
          {/* Workspaces */}
          {workspaces && (
            <MultiSelectObject
              defaultOptions={workspaces.map((workspace) => ({
                key: workspace._id,
                value: workspace.name,
                selected: workspace.selected || false,
                object: workspace,
              }))}
              searchableFields={["name"]}
              title="Assign Workspaces"
              onChange={setWorkspaces}
            />
          )}
        </div>

        <div className="sm:w-1/2 w-full px-2">
          {/* Groups */}
          {groups && (
            <MultiSelectObject
              key={groupChangeKey}
              defaultOptions={groups
                .filter((group) =>
                  workspaces
                    .filter((item) => item.selected)
                    .map((item) => item.key)
                    .includes(group.workspace_id._id)
                )
                .map((group) => {
                  return {
                    key: group._id || group.id,
                    value: group.name,
                    selected: selectedGroups.includes(group._id || group.id),
                    // group.selected || false,
                    object: group,
                  };
                })}
              groupedByField="workspace_id.name"
              // uniqueIdentifier="workspace_id._id"
              searchableFields={["name"]}
              title="Assign groups"
              onChange={(groups, group) => handleSelectedGroups(groups, group)}
            />
          )}
        </div>
        <div className="w-full">
          <TextAccordion
            headerText="Advanced features"
            headerTextPosition="left">
            {typeof getValue("update") === "boolean" && (
              <RadioGroup
                title="Send welcome email on setup"
                subtitle="Disabling this will allow you to manually enter a password"
                options={[
                  { id: "send-email", title: "Send invite email" },
                  { id: "manual", title: "Manually set password" },
                ]}
                defaultChecked={getValue("update") ? "send-email" : "manual"}
                onChange={(id) => updateState(id === "send-email", "update")}
                layout="horizontal"
              />
            )}
            <div className={classNames("grid gap-y-3 transition-all duration-150", getValue("update") ? "mt-2 max-h-0 overflow-hidden opacity-0" : "mt-6 opacity-100 max-h-[200px]")}>
              <p className="p-0 text-gray-300 text-sm">
                Please enter a <span className="text-gray-400 font-semibold px-[2px]">temporary</span> password that should be reset upon logging in.
              </p>
              <div className="w-full flex gap-y-2 gap-x-4">
                {/* Password */}
                <div className="w-full">
                  <Input
                    autoComplete="new-password"
                    type="password"
                    label="Password"
                    value={getValue("password")}
                    onChange={(e) => updateState(e.target.value, "password")}
                  />
                </div>

                {/* Confirm Password */}
                <div className="w-full">
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="confirm-password"
                    label="Confirm password"
                    value={getValue("confirm_password")}
                    onChange={(e) => updateState(e.target.value, "confirm_password")}
                  />
                </div>
              </div>
            </div>
          </TextAccordion>
        </div>
      </div>
    </FormContainer>
  );
};

export default AddUserForm;
