import axios from "axios";
import { refreshTokenVerify } from "src/actions/auth";
import { apiUrl } from "src/config/host";

export const fetchData = async (method, host, body, signal = null, customHeaders = {}, contentType = "application/json") => {
  let finalBody = body;

  const token = localStorage.getItem("access_token") || body?.token || customHeaders?.Authorization || null;

  if (contentType === "application/json") {
    if (!Array.isArray(body)) {
      finalBody = { ...body, token };
    }
    let signalParam = signal ? { signal } : {};
    const res = await fetch(host, {
      method,
      headers: new Headers({
        "Content-Type": contentType,
        Accept: "application/json",
        Authorization: finalBody.token,
        ...customHeaders,
      }),
      body: method !== "GET" && body ? contentType.includes("form-data") ? body : JSON.stringify(finalBody) : null,
      ...signalParam,
    });

    if (res.status === 401) {
      const resData = await res.json();

      if (!resData?.expired) {
        return res
      }

      try {
        const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify()
        if (refreshTokenRes.status !== 200) return res 
        
        return await fetch(host, {
          method,
          headers: new Headers({
            "Content-Type": contentType,
            Accept: "application/json",
            ...customHeaders,
            Authorization: refreshTokenData.refresh_token,
          }),
          body: method !== "GET" && body ? contentType.includes("form-data") ? body : JSON.stringify(finalBody) : null,
          ...signalParam,
        });
      } catch (err) {
        return res
      }
    }

    return res
  } else {
    const request = await axios.request({
      url: host,
      method,
      data: finalBody,
      headers: { ...customHeaders, "Content-Type": contentType, Authorization: token, Accept: "application/json" },
    });

    if (request.status === 401) {
      if (!request.data?.expired) {
        return request
      }

      try {
        const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify()

        if (refreshTokenRes.status !== 200) return request

        return await axios.request({
          url: host,
          method,
          data: finalBody,
          headers: { 
            ...customHeaders, 
            "Content-Type": contentType, 
            Authorization: refreshTokenData.refresh_token, 
            Accept: "application/json" 
          },
        });
      } catch (err) {
        return request
      }
    }

    return request;
  }
};
