import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDetails, updateUser } from "src/actions/user";
import Button from "src/components/Shared/Buttons/Button";
import EditUserForm from "src/components/Users/Edit/ViewProfile/EditUserForm";
import { objectToFormData } from "src/helpers";
import useFetch from "src/hooks/useFetch";

const UserProfileDetailsEdit = ({ workspaces = [], groups = [], setWorkspaces = () => {}, setGroups = () => {}, fetchUser = () => {}, me, ...props }) => {
  const [updatedUserFields, setUpdatedUserFields] = useState({});

  // const [userLoaded, setUserLoaded] = useState(false);
  const [successButtonStart, setSuccessButtonStart] = useState(false);

  const [isAdmin, setIsAdmin] = useState();
  const { id } = useParams();

  const {
    response: { data: user },
    refreshData: refreshUser,
  } = useFetch(`/users/details`, {
    method: "POST",
    data: { id: useParams().id ?? null },
  });

  useEffect(() => {
    refreshUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (user?.type === "admin") {
      setIsAdmin(true);
    }
  }, [user?.type]);

  const onSubmit = async () => {
    setSuccessButtonStart(true);

    const updateData = {
      ...user,
      type: !isAdmin ? (user.type === "editor" ? "editor" : "user") : "admin",
      workspaceIds: workspaces.filter((item) => item.selected).map((item) => item._id),
      ...updatedUserFields,
    };

    const convertedPayload = objectToFormData(updateData);

    try {
      const message = await props.updateUser(convertedPayload, true);
      // const message = await props.updateUser(convertedPayload);
      setSuccessButtonStart(false);
      fetchUser(id);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
      setSuccessButtonStart(false);
    }
  };

  const handleSetUser = (key, value) => {
    // const passRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])[^\s]{8,}$/;
    setUpdatedUserFields({ ...updatedUserFields, [key]: value });
  };
  return (
    <>
      <EditUserForm
        isAdmin={isAdmin}
        setIsAdmin={setIsAdmin}
        user={user}
        setUser={handleSetUser}
        workspaces={workspaces}
        groups={groups}
        setWorkspaces={setWorkspaces}
        setGroups={setGroups}
        {...user}
        {...updatedUserFields}
      />
      {user?.type === "admin" && me?.type !== "admin" ? (
        <></>
      ) : (
        <div className="mt-5 flex w-full justify-end gap-x-3 pt-3">
          <Button
            version="gray"
            onClick={() => {
              refreshUser();
              setUpdatedUserFields({});
            }}>
            Clear all
          </Button>
          <Button
            type="button"
            onClick={onSubmit}
            disabled={successButtonStart}
            loading={successButtonStart}>
            Save
          </Button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserDetails, updateUser })(UserProfileDetailsEdit);
