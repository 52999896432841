import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { dispatcher } from "src/actions/helpers/dispatcher";
import { GET_USERS, GET_USER, GET_USER_DETAILS, CLEAR_USER_DETAILS, REMOVE_USER, LOGGED_IN, GET_GROUP_DATA, REMOVE_GROUP, ADD_GROUP, EDIT_GROUP } from "src/actions/type";

export const addUser = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/users/add${!body.existing ? "" : "-existing"}`, body);
    const data = await res.json();

    if (data.status === 200) {
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageUserRole = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/users/manage-type`, body);
    const data = await res.json();

    if (data.status === 200) {
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getGroupData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/groups/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_GROUP_DATA, payload: data.data });
        return data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const addGroup = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/groups`, body);
    const data = await res.json();

    if (data.status === 200) {
      if (data.data) {
        dispatch({ type: ADD_GROUP, payload: data.data });
      }

      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editGroupData = (body) => async (dispatch) => {
  try {
    const res = await fetchData("PUT", `${apiUrl}/groups `, body);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: EDIT_GROUP, payload: data.data });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteGroupData = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/groups/remove `, { id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: REMOVE_GROUP, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const uploadUsersByCSV = (body) => async (dispatch) => {
  try {
    // const res = await fetchData("POST", `${apiUrl}/users/csv-user-upload?uploadType=api`, body); // Test for Domo dataset
    const res = await fetchData("POST", `${apiUrl}/users/csv-user-upload?uploadType=manual`, body); // Use for CSV
    const resData = await res.json();

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateUser =
  (body, formData = false) =>
  async (dispatch, getState) => {
    try {
      let res = {};
      let data = {};
      if (formData) {
        res = await fetchData("POST", `${apiUrl}/users/edit`, body, null, {}, "multipart/form-data");
        data = res.data;
      } else {
        res = await fetchData("POST", `${apiUrl}/users/edit`, body);
        data = await res.json();
      }

      if (data.status === 200) {
        const state = getState();

        if (state.auth.user?._id === data.data?._id) {
          dispatch({ type: LOGGED_IN, payload: data.data });
        }

        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const updateUserPageAccess = (body) => async (dispatch, getState) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/users/edit-page-access`, body);
    const data = await res.json();

    if (data.status === 200) {
      const state = getState();

      if (state.auth.user?._id === data.data?._id) {
        dispatch({ type: LOGGED_IN, payload: data.data });
      }

      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageUserStatus = (user) => async (dispatch, getState) => {
  try {
    const state = getState();
    const findUser = Object.values(state.users).find((userData) => userData._id === user._id);
    if (findUser && !findUser.default_user) {
      if (state.site.api_state === "DOMO_DATASET") {
        return;
      }
    }

    const res = await fetchData("POST", `${apiUrl}/users/manage-status`, { id: user._id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_USER, payload: { ...user, active_status: !user.active_status } });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getMenuUserList = (payload, signal = null, url = "/users", httpType = "GET", dispatchType = GET_USERS, onSuccess = (data) => data) => dispatcher(payload, signal, url, httpType, dispatchType, onSuccess);

export const getUsers =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("GET", `${apiUrl}/users/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_USERS, payload: data.data });
        return data.meta.count;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getUserDetails =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/users/details`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_USER_DETAILS, payload: data.data });
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const clearUserDetails =
  (body = null) =>
  (dispatch) =>
    dispatch({
      type: CLEAR_USER_DETAILS,
      payload: {},
    });

export const removeUserPermission = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/users/permissions/remove`, { id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: "EMPTY", payload: {} });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const changePassword = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/users/change-password`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteUser = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/users/remove`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      dispatch({ type: REMOVE_USER, payload: body.id });
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
