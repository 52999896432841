import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { userPermissionList, userWorkspacesAndAssociatedPermissions } from "src/actions/workspace";
import Section from "src/components/Shared/Containers/Section";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoneFound from "src/components/Shared/NoData/NoneFound";
import { H3 } from "src/components/Shared/Text/Headers";
import UserPermissionTabData from "src/components/Users/Edit/Permissions/UserPermissionTabData";
import { classNames } from "src/helpers/classNames";

const UserPermissions = ({ ...props }) => {
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("user");
  const tabs = [
    { name: "Pages", code: "page" },
    { name: "Users", code: "user" },
    { name: "Groups", code: "group" },
    { name: "Permissions", code: "permission" },
    { name: "Layout & Styles", code: "layout" },
    { name: "Integrations", code: "integration" },
  ];

  useEffect(() => {
    if (id) {
      const ac = new AbortController();
      const loadData = async () => {
        try {
          // await props.userPermissionList({ user_id: id }, ac.signal);
          const workspace = await props.userWorkspacesAndAssociatedPermissions({ user_id: id }, ac.signal);
          setWorkspaceList(workspace);
        } catch (error) {
          toast.error(error.message);
        } finally {
          setLoaded(true);
        }
      };
      loadData();
      return () => ac.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Section>
      <H3 caption="Workspace editors have access to only the channels that you specify.">Manage editor permissions</H3>
      <div className="mt-8 relative w-full rounded-md space-y-4 h-auto">
        {loaded ? (
          <>
            <div className="relative w-full max-w-9xl shadow rounded-md bg-white border border-slate-100">
              <div className="relative mb-5 bg-slate-100">
                <nav
                  className="flex"
                  aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.code}
                      onClick={() => setSelectedTab(tab.code)}
                      className={classNames(tab.code === selectedTab ? "!bg-white text-slate-800 " : "text-slate-400 hover:bg-slate-50 hover:text-slate-600", "py-4 px-6 bg-transparent w-full flex justify-center items-center rounded-t-md cursor-pointer")}
                      aria-current={tab.code === selectedTab ? "page" : undefined}>
                      <span className="font-semibold">{tab.name}</span>
                    </div>
                  ))}
                </nav>
              </div>
              {workspaceList.length > 0 ? (
                <>
                  <div className="mt-4">
                    <UserPermissionTabData
                      workspaceList={workspaceList}
                      selectedTab={selectedTab}
                    />
                  </div>
                </>
              ) : (
                <div className="h-full w-full p-4">
                  <NoneFound />
                </div>
              )}
            </div>
          </>
        ) : (
          <Preloader />
        )}
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { userWorkspacesAndAssociatedPermissions, userPermissionList })(UserPermissions);
