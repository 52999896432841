import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Button from "src/components/Shared/Buttons/Button";
import StylesPreview from "src/components/Shared/Styles/StylesPreview";
import { baseUrl, noLogo, publicImageStorage } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";

const StylesBuilder = ({
  data = {},
  layoutType,
  handleBrowserTabText = null,
  onSubmit = () => {},
  onSquareLogoSubmit = () => {},
  title = "",
  secondaryTitle = "",
  headerContent = null,
  setWorkspace = () => {},
  propsButtonOption = null,
  setButtonIsDisabled = () => {},
  setSquareLogoButtonIsDisabled = () => {},
  updatedSite = {},
  setUpdatedSite = () => {},
  getSiteData = () => {},
  handleStylesDataChange = () => {},
  handleSquareLogoStylesDataChange = () => {},
  ...props
}) => {
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];
  const { id } = useParams();

  const onClear = () => {
    setButtonIsDisabled(true);
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData("layout"),
    });
  };

  const onSquareLogoClear = () => {
    setSquareLogoButtonIsDisabled(true);
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData("square_logo"),
    });
  };

  const onImageChange = (e, type) => {
    let changeType = "square_logo";
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        handleSquareLogoStylesDataChange({ [changeType]: e.target.files[0] });

        setWorkspace((workspace) => ({
          ...workspace,
          [changeType]: e.target.files[0],
        }));
      }
    }
  };

  const ImageHandler = ({ image = "", layout = "square" }) => {
    const [currentImage, setCurrentImage] = useState(null);
    const [imageUrl, setImageUrl] = useState();

    const areFilesEqual = (file1, file2) => {
      // Check if both files have the same name, size, and type
      if (!(file1 instanceof File) || !(file2 instanceof File)) return false;

      return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type && file1.lastModified === file2.lastModified;
    };

    let imageSource = useMemo(() => {
      if (image instanceof File && areFilesEqual(image, currentImage)) {
        return imageUrl;
      }

      let objUrl = "";

      if (image instanceof File) {
        objUrl = URL.createObjectURL(image);
        setImageUrl(objUrl);
        setCurrentImage(image);
      }

      return image instanceof File ? objUrl : image?.includes(publicImageStorage) ? image + "?" + Date.now() : image?.includes("data:image/") ? image : baseUrl + image;
    }, [image, currentImage, imageUrl]);

    const imageComponent = useCallback(() => {
      return (
        <div
          className={`relative group cursor-pointer ${layout === "rectangle" ? "w-40" : "w-20"} h-20 hover:border-highlightColor border border-gray-200/60 rounded-md bg-gray-300/20`}>
          {updatedSite._id && (
            <img
              src={imageSource}
              alt="Logo"
              className="w-full h-full object-contain rounded-lg"
              onError={(e) => (e.target.src = noLogo)}
            />
          )}
          {authorizeUserComponentAccess(props.user, updatedSite._id, "layout", ["update"]) && (
            <>
              <label
                htmlFor={`change_logo_square_logo`}
                className="cursor-pointer absolute top-0 left-0 z-40 h-full w-full">
                <div className="cursor-pointer absolute bottom-0 h-10 left-0 transition-all duration-200 bg-gray-700/60 rounded-b group-hover:bg-highlightColor text-lg text-white flex w-full justify-center items-center gap-x-2 px-2 py-1">
                  <PhotoIcon className="h-6 w-6 stroke-2" />
                  Edit
                </div>
              </label>
              <input
                type="file"
                id={`change_logo_square_logo`}
                name={`change_logo_square_logo`}
                className="hidden"
                onChange={(e) => onImageChange(e)}
              />
            </>
          )}
          {imageSource && authorizeUserComponentAccess(props.user, id, "layout", ["delete"]) && (
            <div
              className="group-hover:flex cursor-pointer hidden bg-gray-200 hover:bg-gray-300 absolute h-8 w-8 rounded-full text-center -top-3 -right-3 z-40 justify-center items-center transition-all"
              onClick={() => {
                handleSquareLogoStylesDataChange({ [`square_logo`]: "" });
                setWorkspace((workspace) => ({ ...workspace, square_logo: null }));
              }}>
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </div>
          )}
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSource, layout, props.user, updatedSite._id]);

    return imageComponent();
  };

  const ImageHandlerMemoized = useMemo(
    () => (
      <ImageHandler
        image={updatedSite.square_logo}
      />
    ),
    [updatedSite.square_logo]
  );

  return (
    <div className="max-w-7xl grid space-y-8">
      {propsButtonOption && (
        <div className="mb-10">
          <TextAccordion
            headerTextPosition="left"
            headerText="Advanced details">
            <div className="flex items-end justify-between">
              <div className="flex-shrink">
                <div className="text-gray-700 text-base font-semibold">Square logo</div>
                <div className="text-gray-400 text-sm mb-2 font-medium">Recomended Image Ratio 1x1</div>
                {props.user?._id && ImageHandlerMemoized}
              </div>
              <div className="flex flex-end space-x-4 ml-auto">
                <Button
                  version="gray"
                  disabled={propsButtonOption?.squareLogoButtonLoader}
                  onClick={onSquareLogoClear}>
                  Undo changes
                </Button>
                {!propsButtonOption?.squareLogoButtonLoader ? (
                  authorizeUserComponentAccess(props.user, id, "layout", ["update"]) && (
                    <Button
                      disabled={propsButtonOption?.squareLogoButtonIsDisabled}
                      onClick={() => onSquareLogoSubmit({ _id: updatedSite._id, square_logo: updatedSite.square_logo })}>
                      Update
                    </Button>
                  )
                ) : (
                  <Button disabled={propsButtonOption?.squareLogoButtonLoader}>
                    Update
                    <ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" />
                  </Button>
                )}
              </div>
            </div>
          </TextAccordion>
        </div>
      )}
      <StylesPreview
        layoutType={layoutType}
        handleBrowserTabText={handleBrowserTabText}
        menu={["Home", "About Us", "Feedback"]}
        styles={updatedSite}
        user={props.user}
        setWorkspace={setWorkspace}
        onChange={handleStylesDataChange}
        workspaceDetails={data}
      />
      <div className="border-t border-gray-200 mt-5 pt-3 w-full flex justify-end gap-x-4">
        <Button
          version="gray"
          disabled={propsButtonOption?.buttonLoader}
          onClick={onClear}>
          Undo changes
        </Button>
        {!propsButtonOption?.buttonLoader ? (
          authorizeUserComponentAccess(props.user, id, "layout", ["update"]) && (
            <Button
              disabled={propsButtonOption?.buttonIsDisabled}
              onClick={() => onSubmit(updatedSite)}>
              Update
            </Button>
          )
        ) : (
          <Button disabled={propsButtonOption?.buttonLoader}>
            Update
            <ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" />
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(StylesBuilder);
