import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { refreshTokenVerify } from "src/actions/auth";
import { apiUrl } from "src/config/host";
import { urlBuilder } from "src/helpers/urlBuilder";

const useFetch = (url, options = {}, token = "") => {
  let accessToken = localStorage.getItem("access_token");
  let headers = { "Content-Type": "application/json", Accept: "application/json" };
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: accessToken,
    };
  }

  const [response, setResponse] = useState({ data: [], meta: {} });
  const [status, setStatus] = useState({ idle: true, fetching: false, done: false, fail: false });

  let params = options.params;
  delete options.params;

  const optionsRef = useRef({
    headers,
    ...options,
  });

  const requestData = () => {
    setStatus({ idle: false, fetching: true, done: false, fail: false });
    (async () => {
      try {
        let response = await axios(apiUrl + urlBuilder(url, params), { ...optionsRef.current, data: options.data, params: options.query, method: optionsRef.current.method || "get" });

        if (response.status === 401 && response.data?.expired) {
          const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify()

          if (refreshTokenRes.status === 200) {
            optionsRef.current.headers.Authorization = refreshTokenData.refresh_token

            response = await axios(apiUrl + urlBuilder(url, params), { ...optionsRef.current, data: options.data, params: options.query, method: optionsRef.current.method || "get" });
          }
        } 

        setResponse(response.data);
        setStatus({ idle: false, fetching: false, done: true, fail: false });
      } catch (e) {
        if (e?.response?.status === 401 && e?.response?.data?.expired) {
          const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify()

          if (refreshTokenRes !== 200) {
            setStatus({ idle: false, fetching: false, done: false, fail: true });
          } else {
            optionsRef.current.headers.Authorization = refreshTokenData.refresh_token

            const response = await axios(apiUrl + urlBuilder(url, params), { ...optionsRef.current, data: options.data, params: options.query, method: optionsRef.current.method || "get" });

            setResponse(response.data);
            setStatus({ idle: false, fetching: false, done: true, fail: false });
          }
        } else {
          setStatus({ idle: false, fetching: false, done: false, fail: true });
        }
      }
    })();
  };

  useEffect(() => {
    if (url) {
      requestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (token) {
      optionsRef.current.headers.Authorization = token;
      requestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const refreshData = () => {
    if (url) {
      requestData();
    }
  };

  return { response, status, refreshData };
};

export default useFetch;
