import { apiUrl } from "src/config/host";
import axios from "axios";
import { toast } from "react-toastify";
import { urlBuilder } from "src/helpers/urlBuilder";
import { refreshTokenVerify } from "src/actions/auth";

const builderQueryString = (queries = []) => {
  let queryString = "";
  if (queries) {
    queryString = new URLSearchParams(queries);
    queryString = "?" + queryString.toString();
  }

  return queryString;
};

const apiRequest = async (method = "put", url = "", payload = { body: null, params: {}, queries: null }, options = { useCustomUrl: false, showToastMessage: false }) => {
  let params = payload.params;
  delete payload.params;

  let defaultPayload = { body: null, queries: null };
  payload = { ...payload, defaultPayload };

  let defaultOptions = { useCustomUrl: false, showToastMessage: false };
  options = { ...options, defaultOptions };

  try {
    let queryString = "";
    if (payload.queries != null) {
      queryString = builderQueryString(payload?.queries);
    }

    let accessToken = localStorage.getItem("access_token");

    let headers = { "Content-Type": "application/json", Accept: "application/json" };

    if (accessToken) {
      headers = {
        ...headers,
        Authorization: accessToken,
      };
    }
    let request = axios.create({
      baseURL: options.useCustomUrl ? "" : apiUrl,
      headers,
    });

    let res = await request[method.toLowerCase()](`${urlBuilder(url, params)}${queryString}`, payload.body);

    if (res.status === 401 && res.data?.expired) {
      const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify()

      if (refreshTokenRes.status === 200) {
        headers.Authorization = refreshTokenData.refresh_token

        request = axios.create({
          baseURL: options.useCustomUrl ? "" : apiUrl,
          headers,
        });

        res = await request[method.toLowerCase()](`${urlBuilder(url, params)}${queryString}`, payload.body);
      }
    }

    if (res.status === 200) {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.success(res.data?.message);
        }
        if (res.data?.data?.message) {
          toast.success(res.data?.data?.message);
        }
      }
      return res;
    } else {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.error(res.data?.message);
        }
        if (res.data?.data?.message) {
          toast.error(res.data?.data?.message);
        }
      }
      throw new Error(res?.message || res);
    }
  } catch (error) {
    if (error?.response?.status === 401 && error?.response?.data?.expired) {
      const queryString = builderQueryString(payload?.queries);

      const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify()

      if (refreshTokenRes.status === 200) {
        const headers =  {
          "Content-Type": "application/json", Accept: "application/json" 
        };

        headers.Authorization = refreshTokenData.refresh_token

        const request = axios.create({
          baseURL: options.useCustomUrl ? "" : apiUrl,
          headers,
        });

        const res = await request[method.toLowerCase()](`${urlBuilder(url, params)}${queryString}`, payload.body);

        if (res.status === 200) {
          if (options.showToastMessage) {
            if (res.data?.message) {
              toast.success(res.data?.message);
            }
            if (res.data?.data?.message) {
              toast.success(res.data?.data?.message);
            }
          }
          return res;
        } else {
          if (options.showToastMessage) {
            if (res.data?.message) {
              toast.error(res.data?.message);
            }
            if (res.data?.data?.message) {
              toast.error(res.data?.data?.message);
            }
          }
          throw new Error(res?.message || res);
        }
      } else {
        if (options.showToastMessage) {
          toast.error(error?.response?.data.message || error?.response?.data);
        }
        throw new Error(error?.response?.data.message || error?.response?.data || error);
      }
    } else {
      if (options.showToastMessage) {
        toast.error(error?.response?.data.message || error?.response?.data);
      }
      throw new Error(error?.response?.data.message || error?.response?.data || error);
    }
  }
};

export { builderQueryString, apiRequest };
