import NoProfile from "src/assets/png/user-default.png";
import NoFavicon from "src/assets/png/image_favicon-default.png";
import NoLogo from "src/assets/png/image_logo-default.png";
import DomoGreyScaleJWT from "src/assets/png/domo-grayscale-jwt.png"

/* Production: Digital Ocean */
// export const baseUrl = "https://domo-portal-admin-api-29ypc.ondigitalocean.app";

/* Production: CPanel */
export const baseUrl = "https://admin.verus-rx.com";

/* Localhost */
// export const baseUrl = "http://localhost:3001";

export const publicImageStorage = ".s3.us-west-2.amazonaws.com";
export const apiUrl = baseUrl + "/api";
export const filePath = baseUrl + "/uploads";
export const noProfile = NoProfile;
export const noFavicon = NoFavicon;
export const domoGrayscaleJWT = DomoGreyScaleJWT
export const noLogo = NoLogo;
