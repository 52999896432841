import { ArrowPathIcon, Bars3Icon, CheckCircleIcon, ChevronDownIcon, Cog6ToothIcon, PlusIcon, WindowIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Members from "src/components/Shared/Members";
import QuickMenu from "src/components/Shared/QuickMenu";
import WorkspaceGroupAuthorizeUserComponent from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupAuthorizeUserComponent";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import useDebounce from "src/hooks/useDebounce";
import { urlBuilder } from "src/helpers/urlBuilder";

const WorkspaceGroupItem = ({
  viewOnly = false,
  defaultGroup = {},
  pageOptions = [],
  operatorOptions = [],
  workspaceId,
  workspaceDetails,
  trustedAttributeArr,
  valueType,
  workspaceUsers = [],
  importable = false,
  setDeleteGroup = () => {},
  setCloneGroup = () => {},
  refresh = () => {},
  setIsAddUserModal = () => {},
  setCurrentGroupId = () => {},
  setCurrentGroupUserId = () => {},
  userModalOpen = () => {},
  draggableProps,
  dragHandleProps,
  innerRef,
  ...props
}) => {
  // const { id } = useParams();

  const [hideDetails, setHideDetails] = useState(true);
  const [items, setItems] = useState([]);
  const [group, setGroup] = useState(defaultGroup);
  const [groupChanges, setGroupChanges] = useState(0);

  // const [groupImportUserModalOpen, setGroupImportUserModalOpen] = useState(false);
  // const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");

  const pageJson = {
    _id: null,
    page_id: null,
    filters: [],
    isDraft: true,
  };

  const savePermissionChanges = async () => {
    await apiRequest(
      "put",
      urlBuilder(`/workspaces/:workspace_id/groups/:group_id`, { workspace_id: workspaceId, group_id: group._id }),
      {
        body: {
          ...group,
          permissions: group.permissions,
        },
      },
      { showToastMessage: true }
    );
    setGroupChanges(0);
  };

  const onClear = () => {
    setGroup(defaultGroup);
    setGroupChanges(0);
  };

  useEffect(() => {
    setGroup(defaultGroup);
  }, [defaultGroup]);

  useEffect(() => {
    let defaultItems = [];
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"], true)) {
      defaultItems.push({
        name: "Manage users",
        onClick: () => userModalOpen(true, group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      defaultItems.push({
        name: "Add page",
        onClick: () => {
          setHideDetails(false);
          setGroup({ ...group, permissions: [...group.permissions, pageJson] });
        },
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["create", "update"]) && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      defaultItems.push({
        name: "Clone group",
        onClick: () => setCloneGroup(group),
      });
    }

    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["delete"]) && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      if (defaultItems.length > 0)
        defaultItems.push({
          type: "hr",
        });

      defaultItems.push({
        name: "Delete group",
        onClick: () => setDeleteGroup(group),
        color: "text-red-500",
      });
    }

    setItems(defaultItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.me, group, workspaceDetails?.workspace_type]);

  const [name, setName] = useState("");

  useEffect(() => {
    if (group) {
      setName(group.name || "");
    }
  }, [group]);

  const loadingColors = {
    IDLE: "group-focus:text-transparent",
    WAITING: "group-focus:text-slate-700/50",
    DONE: "group-focus:text-green-700/50",
  };

  const handleGroupUpdate = async (name = " ") => {
    await apiRequest("put", `/workspaces/${workspaceId}/groups/${group._id ? group._id : group.id}`, { body: { ...group, name } });
  };

  const [debouncedNameUpdate, debounceNameStatus] = useDebounce(handleGroupUpdate, 500);

  const handleChange = async (e) => {
    setName(e.target.value);
    debouncedNameUpdate(e.target.value);
  };

  return (
    <>
      <div
        className="relative bg-white shadow border border-slate-200 rounded-md max-w-full"
        ref={innerRef}
        {...draggableProps}>
        {authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && !viewOnly && workspaceDetails?.workspace_type === "IFRAME_EMBED" && (
          <button
            {...dragHandleProps}
            type="button"
            className="w-8 min-w-[32px] h-16 rounded-lg rounded-r-none flex items-center justify-center border border-gray-200 text-gray-700 outline-none ring-0 bg-gray-100 cursor-move absolute top-[30px] -left-[32px] -translate-y-1/2">
            <Bars3Icon className="w-5 h-5 stroke-2" />
          </button>
        )}
        <div className="flex items-center justify-between py-4 px-6 gap-10">
          <div className="relative flex items-center gap-x-2 w-full">
            {workspaceDetails?.workspace_type === "IFRAME_EMBED" ? (
              <button className="group relative">
                <div className={classNames("p-1 font-light py-.5 px-1 flex gap-x-2 items-center overflow-hidden transition-all duration-75 absolute -top-1 -right-7", loadingColors[debounceNameStatus])}>
                  {debounceNameStatus === "WAITING" && (
                    <p className="rounded-full flex bg-gray-500/20 items-center">
                      <ArrowPathIcon className="h-5 w-5 animate-spin text-white" />
                    </p>
                  )}
                  {debounceNameStatus === "DONE" && (
                    <p className="rounded-full bg-green-600/40 text-white flex items-center">
                      <CheckCircleIcon className="h-5 w-5 text-white" />
                    </p>
                  )}
                </div>
                {authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && !viewOnly ? (
                  <Input
                    type="text"
                    autoComplete="on"
                    name="workspace-name"
                    value={name}
                    onChange={handleChange}
                    inputClassNames="w-full pr-4 focus:ring-0 focus:border-highlightColor bg-transparent hover:border-slate-400/20 border border-transparent flex items-center gap-x-2 text-base px-2 py-1 rounded transition-all duration-75 text-gray-800"
                  />
                ) : (
                  <p className="font-semibold text-slate-700 truncate">{group.name}</p>
                )}
              </button>
            ) : (
              <p className="font-semibold text-slate-700 truncate">{group.name}</p>
            )}
          </div>
          <div className="flex items-center">
            <div className="flex gap-x-3 justify-end items-center pr-4">
              <div className="justify-end hidden sm:flex items-center">
                {group.users?.length > 0 && (
                  <div
                    onClick={() => {
                      userModalOpen(true, group);
                    }}
                    className="relative flex gap-x-3 cursor-pointer hover:underline">
                    {group.users?.length > 0 && <Members group={group} />}
                  </div>
                )}
                {group.permissions?.length > 0 && (
                  <>
                    {group.users?.length > 0 && <div className="hidden md:flex ml-4 mr-2 bg-gray-400 h-[4px] w-[4px] rounded-full" />}
                    <div className="hidden w-10 md:flex">
                      <div
                        className="relative cursor-pointer hidden sm:flex items-center text-sm gap-x-1 py-2 px-2 rounded text-gray-400 border-transparent hover:bg-gray-50 hover:shadow-sm hover:text-gray-500"
                        onClick={() => setHideDetails(!hideDetails)}>
                        <WindowIcon className="h-6 w-6" />
                        <p className={classNames("h-5 min-w-5 flex items-center justify-center absolute bottom-0 bg-white border-[1px] p-1 rounded-full border-gray-100", group.permissions?.length > 10 ? "-right-2" : "right-0")}>{group.permissions?.length} </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {(authorizeUserComponentAccess(props.me, workspaceId, "group", ["delete"]) || authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])) && !viewOnly && (
                <QuickMenu
                  icon={
                    <div className="text-gray-400 hover:text-gray-500 h-full hover:bg-gray-50 py-2 px-2 rounded-md flex items-center justify-center">
                      <Cog6ToothIcon className="h-6 w-6" />
                    </div>
                  }
                  position="RIGHT"
                  items={items}
                />
              )}
            </div>
            {props?.workspaceDetailsData?.workspace_type === "IFRAME_EMBED" && (
              <div className="ml-auto flex items-center gap-5">
                <Button
                  onClick={() => setHideDetails(!hideDetails)}
                  version="secondary"
                  className="!py-1 !px-1 !border-0 !text-slate-300 !outline-none !ring-0 !ring-transparent !shadow-none">
                  <ChevronDownIcon
                    className={classNames("h-5 w-5 transition-all duration-150 ", hideDetails ? "" : "rotate-180")}
                    aria-hidden="true"
                  />
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className={classNames("relative", !hideDetails ? "max-h-[200000px] py-3 sm:py-4 px-3 sm:px-6 border-t border-slate-200" : "overflow-hidden max-h-[0px]")}>
          <WorkspaceGroupAuthorizeUserComponent
            group={group}
            permissions={group?.permissions || []}
            setGroup={setGroup}
            groupChanges={groupChanges}
            setGroupChanges={setGroupChanges}
            me={props.me}
            pageOptions={pageOptions}
            operatorOptions={operatorOptions}
            viewOnly={viewOnly}
            trustedAttributeArr={trustedAttributeArr}
            valueType={valueType}
            workspaceDetails={workspaceDetails}
            workspaceId={workspaceId}
            addPermission={(e, index) => {
              setGroupChanges(groupChanges + 1);
              setGroup({
                ...group,
                permissions: group.permissions.map((p, i) => {
                  if (i === index) {
                    return e;
                  } else {
                    return p;
                  }
                }),
              });
            }}
            removePermission={(e, index) => {
              setGroupChanges(groupChanges + 1);
              setGroup({
                ...group,
                permissions: group.permissions.filter((p, i) => i !== index),
              });
            }}
            authorizeUserComponentAccessPermission={authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])}
          />
          <div className="w-full pt-4 flex justify-end max-w-9xl">
            <Button
              version="gray"
              onClick={() => setGroup({ ...group, permissions: [...group.permissions, pageJson] })}>
              <PlusIcon className="h-4 w-4" />
              Add page
            </Button>
          </div>
        </div>
        {!viewOnly && !hideDetails && authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && (
          <div className={classNames("p-4 border-t-[1px] border-gray-200 w-full flex justify-end gap-x-4 mt-20 sm:mt-4 transition-all duration-150 pt-4", groupChanges > 0 ? "bg-gray-50" : "")}>
            <Button
              version="gray"
              onClick={onClear}
              disabled={groupChanges < 1}>
              Undo changes
            </Button>
            <Button
              onClick={savePermissionChanges}
              disabled={groupChanges < 1}>
              Update
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(WorkspaceGroupItem);
