import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import React from "react";
import { filePath } from "src/config/host";
import { classNames } from "src/helpers/classNames";

export default function CSVFormat({ className = "" }) {
  return (
    <div className={classNames("", className)}>
      <a
        href={filePath + "/upload_users_format.csv"}
        className="min-w-[120px] flex font-medium underline focus:outline-none text-gray-400 border-leftNavColor cursor-pointer">
        <div className="group relative hover:bg-gray-200/20 py-1 px-2 flex rounded items-center gap-x-2">
          <ArrowDownTrayIcon className="relative group-hover:top-[0px] top-[-2px] transition-all duration-100 w-6 h-6" /> CSV Format
        </div>
      </a>
    </div>
  );
}
