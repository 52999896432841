import { HomeIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import SiteColorItem from "src/components/Site/SiteColorItem";
import { baseUrl, noFavicon, noLogo, publicImageStorage } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
// import { convertToBase64 } from "src/helpers/image";

const StylesPreview = ({ workspaceDetails, layoutType = "LEFT_NAVIGATION", menu, styles = {}, handleBrowserTabText = null, onChange = () => {}, setWorkspace = () => {}, ...props }) => {
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];
  const { id } = useParams();

  const onImageChange = (e, type) => {
    let changeType = type === "logo" ? "image_logo" : "image_favicon";
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        onChange({ [changeType]: e.target.files[0] });

        setWorkspace((workspace) => ({
          ...workspace,
          [changeType]: e.target.files[0],
        }));
      }
    }
  };

  const ImageHandler = ({ image = "", type = "logo" }) => {
    const [currentImage, setCurrentImage] = useState(null);
    const [imageUrl, setImageUrl] = useState();

    const areFilesEqual = (file1, file2) => {
      // Check if both files have the same name, size, and type
      if (!(file1 instanceof File) || !(file2 instanceof File)) return false;

      return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type && file1.lastModified === file2.lastModified;
    };

    const imageSource = useMemo(() => {
      if (image instanceof File && areFilesEqual(image, currentImage)) {
        return imageUrl;
      }

      let objUrl = "";

      if (image instanceof File) {
        objUrl = URL.createObjectURL(image);
        setImageUrl(objUrl);
        setCurrentImage(image);
      }

      return image instanceof File ? objUrl : image?.includes(publicImageStorage) ? image + "?" + Date.now() : image?.includes("data:image/") ? image : baseUrl + image;
    }, [image, currentImage, imageUrl]);

    const imageComponent = useCallback(() => {
      return (
        <>
          {styles._id && (
            <img
              src={imageSource}
              alt="Logo"
              className="w-full h-full object-contain rounded-lg"
              onError={(e) => (e.target.src = `${type === "logo" ? noLogo : noFavicon}`)}
            />
          )}
          {authorizeUserComponentAccess(props.user, styles._id, "layout", ["update"]) && (
            <>
              <label
                htmlFor={`change_logo_${type}`}
                className="cursor-pointer absolute top-0 left-0 z-40 h-full w-full">
                <div className="cursor-pointer absolute bottom-0 h-10 left-0 transition-all duration-200 bg-gray-700/60 rounded-b group-hover:bg-highlightColor text-lg text-white flex w-full justify-center items-center gap-x-2 px-2 py-1">
                  <PhotoIcon className="h-6 w-6 stroke-2" />
                  Edit
                </div>
              </label>
              <input
                type="file"
                id={`change_logo_${type}`}
                name={`change_logo_${type}`}
                className="hidden"
                onChange={(e) => onImageChange(e, type)}
              />
            </>
          )}
          {imageSource && authorizeUserComponentAccess(props.user, id, "layout", ["delete"]) && (
            <div
              className="group-hover:flex cursor-pointer hidden bg-gray-200 hover:bg-gray-300 absolute h-8 w-8 rounded-full text-center -top-3 -right-3 z-40 justify-center items-center transition-all"
              onClick={() => {
                onChange({ [`image_${type}`]: "" });
                setWorkspace((workspace) => ({ ...workspace, image_logo: null }));
              }}>
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </div>
          )}
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSource, type, props.user, styles._id]);

    return imageComponent();
  };

  return (
    <div className="mt-9 relative w-full flex flex-wrap border border-gray-300 rounded-lg">
      <div className="absolute -top-9 left-4 h-9 border-[1px] border-gray-200 bg-gray-400/20 rounded-t-lg flex px-3 justify-between z-20 items-center">
        <div className="flex items-center gap-x-3">
          {useCallback(
            () => (
              <img
                className="h-7 w-7"
                src={
                  styles.image_favicon instanceof File
                    ? URL.createObjectURL(styles.image_favicon)
                    : styles.image_favicon?.includes(publicImageStorage)
                    ? styles.image_favicon + "?" + Date.now()
                    : styles.image_favicon?.includes("data:image/")
                    ? styles.image_favicon
                    : baseUrl + styles.image_favicon
                }
                onError={(e) => (e.target.src = `${noFavicon}`)}
                alt="logo"
              />
            ),
            [styles.image_favicon]
          )()}

          <div className="max-w-[120px] w-[120px]">{handleBrowserTabText ? handleBrowserTabText : <p className="text-gray-600 mr-4 text-center max-w-[100px] w-[120px] truncate">{styles.name}</p>}</div>
        </div>
        <div className="group absolute z-30 -right-28 transition-all duration-150">
          <div className={`relative group cursor-pointer w-20 h-20 hover:border-highlightColor border bg-white/10 rounded-md`}>
            {useCallback(
              () => (
                <>
                  {props.user?._id ? (
                    <ImageHandler
                      image={styles.image_favicon}
                      type="favicon"
                    />
                  ) : (
                    <></>
                  )}
                </>
              ),
              [styles.image_favicon, props.user?._id]
            )()}
          </div>

          <div className="h-4 w-1 bg-gray-200 group-hover:bg-highlightColor transition-all duration-200 absolute -left-[190px] top-3 z-30" />
          <div className="h-1 w-[190px] bg-gray-200 group-hover:bg-highlightColor transition-all duration-200 absolute -left-[190px] top-3 z-30" />
          <div className="h-4 w-4 rounded-full border-[3px] bg-white group-hover:border-highlightColor transition-all duration-200 border-gray-200 absolute -left-2 top-2 z-30" />
          <div className="h-4 w-4 rounded-full border-[3px] bg-white group-hover:border-highlightColor transition-all duration-200 border-gray-200 absolute -left-[194px] top-5 z-30" />
        </div>
      </div>
      <div
        style={{ backgroundColor: styles.top_bar_color }}
        className="relative w-full flex items-center justify-between h-[76px] px-6 rounded-t-lg">
        <div className={`relative group cursor-pointer w-40 h-20 hover:border-highlightColor border bg-white/10 rounded-md`}>
          {useCallback(
            () => (
              <>
                {props.user?._id ? (
                  <ImageHandler
                    image={styles.image_logo}
                    type="logo"
                    layout="rectangle"
                  />
                ) : (
                  <></>
                )}
              </>
            ),
            [styles.image_logo, props.user?._id]
          )()}
        </div>

        <div className="flex items-center gap-x-2">
          <div
            style={{ backgroundColor: styles.top_bar_text_color }}
            className="h-4 rounded w-24"
          />
          <div className="rounded-full w-6 h-6 bg-gray-100" />
        </div>
        <div
          className={classNames(
            "hidden sm:flex absolute z-10 bg-white flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0 ",
            layoutType === "TOP_NAVIGATION" && "top-10 right-56",
            layoutType === "LEFT_NAVIGATION" && "top-10 right-56",
            layoutType === "CUSTOM_NAVIGATION" && "top-10 right-56"
          )}>
          <SiteColorItem
            onChange={(value) => onChange({ top_bar_color: value })}
            color={styles.top_bar_color}
            name="Header Background"
          />
        </div>
        <div className="hidden sm:flex absolute z-10 -top-11 right-14 bg-white flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-bottom-2 before:right-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-l-0 before:border-t-0">
          <SiteColorItem
            onChange={(value) => onChange({ top_bar_text_color: value })}
            color={styles.top_bar_text_color}
            name="Header text"
          />
        </div>
      </div>

      <div
        style={{ backgroundColor: styles.side_bar_color }}
        className={"relative p-4 " + (layoutType === "LEFT_NAVIGATION" ? "w-5/12 sm:w-3/12 rounded-bl-lg space-y-4" : "w-full flex space-x-4")}>
        {layoutType === "CUSTOM_NAVIGATION" ? (
          <div
            style={{ color: styles.side_bar_color }}
            className="h-5 py-4 px-3 rounded font-medium flex items-center">
            <HomeIcon
              className="h-6 w-6"
              style={{ color: styles.side_bar_text_color }}
            />
          </div>
        ) : (
          <>
            <div
              style={{ color: styles.left_nav_active_text_color, backgroundColor: styles.side_bar_shade_color }}
              className="h-5 py-4 px-3 font-medium flex items-center rounded">
              Pages
            </div>
            {menu.map((mnu) => {
              return (
                <div
                  key={mnu}
                  style={{ color: styles.side_bar_text_color }}
                  className="h-5 py-4 px-3 rounded font-medium flex items-center">
                  {mnu}
                </div>
              );
            })}
          </>
        )}

        {layoutType !== "CUSTOM_NAVIGATION" && (
          <div
            className={
              "absolute z-30 bg-white flex flex-col w-[160px] border border-gray-100 gap-y-4 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0 " +
              (layoutType === "TOP_NAVIGATION" ? "top-14 left-0" : "top-6 left-14")
            }>
            <SiteColorItem
              onChange={(value) => onChange({ side_bar_shade_color: value })}
              color={styles.side_bar_shade_color}
              name="Navigation Active"
            />
            <SiteColorItem
              onChange={(value) => onChange({ left_nav_active_text_color: value })}
              color={styles.left_nav_active_text_color}
              name="Navigation Active Text"
            />
          </div>
        )}
        <div
          className={classNames(
            "absolute z-10 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0 ",
            layoutType === "TOP_NAVIGATION" && "top-14 right-2",
            layoutType === "LEFT_NAVIGATION" && "top-[240px] left-6",
            layoutType === "CUSTOM_NAVIGATION" && "top-14 left-0"
          )}>
          <SiteColorItem
            onChange={(value) => onChange({ side_bar_text_color: value })}
            color={styles.side_bar_text_color}
            name="Navigation text"
          />
          <SiteColorItem
            onChange={(value) => onChange({ side_bar_color: value })}
            color={styles.side_bar_color}
            name="Navigation background"
          />
        </div>
      </div>

      <div
        style={{ backgroundColor: styles.background_color }}
        className={"relative bg-gray-50  rounded-br-lg " + (layoutType === "CUSTOM_NAVIGATION" || layoutType === "TOP_NAVIGATION" ? "w-full" : "w-7/12 sm:w-9/12")}>
        <div className={classNames(`mx-auto w-full space-y-4 p-4 relative`)} style={{
          maxWidth: styles?.max_width || '100%'
        }}>
        <div
          className={classNames(
            "absolute z-20 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 gap-y-4 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0",
            layoutType === "TOP_NAVIGATION" && "top-60 left-32",
            layoutType === "LEFT_NAVIGATION" && "top-14 left-32",
            layoutType === "CUSTOM_NAVIGATION" && "top-60 left-32"
          )}>
          <SiteColorItem
            onChange={(value) => onChange({ highlight_color: value })}
            color={styles.highlight_color}
            name="Highlight"
          />
          {id && (
            <SiteColorItem
              onChange={(value) => onChange({ background_color: value })}
              color={styles.background_color}
              name="Page background"
            />
          )}
        </div>
        <div
          className={classNames(
            "absolute z-20 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 gap-y-4 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0",
            layoutType === "TOP_NAVIGATION" && "top-[240px] -right-4 2xl:-right-36 left-auto before:right-4 2xl:before:right-auto before:left-auto 2xl:before:left-4",
            layoutType === "LEFT_NAVIGATION" && "top-[240px] -right-4 2xl:-right-36 left-auto before:right-4 2xl:before:right-auto before:left-auto 2xl:before:left-4",
            layoutType === "CUSTOM_NAVIGATION" && "top-[240px] -right-4 2xl:-right-36 left-auto before:right-4 2xl:before:right-auto before:left-auto 2xl:before:left-4"
          )}
        >
            <SiteColorItem
              onChange={(value) => {
                onChange({ max_width: value })
              }}
              value={styles.max_width}
              type="input"
              name="Max Width"
            />
        </div>
        <div
          style={{ backgroundColor: styles.highlight_color }}
          className={"h-4 rounded " + (layoutType !== "TOP_NAVIGATION" ? "w-48" : "bg-gray-400")}></div>
        <div className="bg-gray-400 h-4 rounded"></div>
        <div className="bg-gray-400 h-4 rounded"></div>
        <div className="bg-gray-400 h-4 w-48 rounded"></div>
        <div className="h-12"></div>
        <div
          style={{ backgroundColor: styles.highlight_color }}
          className={"h-4 rounded " + (layoutType === "TOP_NAVIGATION" ? "w-48" : "bg-gray-400")}></div>
        <div className="bg-gray-400 h-4 rounded"></div>
        <div className="bg-gray-400 h-4 w-48 rounded"></div>
        <div className="h-48 sm:h-28"></div>
        </div>
      </div>
      <div
        className={classNames(
          "absolute z-30 bg-white flex sm:hidden flex-col w-[160px] border border-gray-100 gap-y-4 py-2 px-2 rounded-xl drop-shadow-md",
          layoutType === "TOP_NAVIGATION" && "bottom-4 right-4",
          layoutType === "LEFT_NAVIGATION" && "bottom-4 right-4",
          layoutType === "CUSTOM_NAVIGATION" && "bottom-4 right-4"
        )}>
        <SiteColorItem
          onChange={(value) => onChange({ top_bar_text_color: value })}
          color={styles.top_bar_text_color}
          name="Header text"
        />
        <SiteColorItem
          onChange={(value) => onChange({ top_bar_color: value })}
          color={styles.top_bar_color}
          name="Header Background"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(StylesPreview);
